import { createRouter, createWebHistory } from "vue-router";
import Dashboard from "../views/Dashboard.vue";
import Repositories from "../views/Repositories.vue";
import Models from "../views/Models.vue";
import AdminModels from "../views/AdminModels.vue";
import modele from "../views/model.vue";
import Signup from "../views/Signup.vue";
import Signin from "../views/Signin.vue";

const routes = [
  {
    path: "/",
    name: "/",
    redirect: "/signin",
  },
  {
    path: "/dashboard",
    name: "Tableau de bords",
    component: Dashboard,
  },
  {
    path: "/repositories",
    name: "Dossiers",
    component: Repositories,
    props: true
  },
  {
    path: "/models",
    name: "Modèles",
    component: Models,
  },
  {
    path: "/modeles",
    name: "List Modèles",
    component: AdminModels,
    props: true
  },
  {
    path: "/model",
    name: "Modèle",
    component: modele,
    props: true
  },
  {
    path: "/signin",
    name: "Signin",
    component: Signin,
  },
  {
    path: "/signup",
    name: "Signup",
    component: Signup,
  },
  
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
});

router.beforeEach((to, from, next) => {
  const token = localStorage.getItem('token');
    // If logged in, or going to the Login page.
    if (token || to.name === 'Signin') {
      // Continue to page.
      next()
    } else {
      // Not logged in, redirect to login.
      next({name: 'Signin'})
    }
});

export default router;

<template>
    <div class="container" id="elzero-app">
        <div id="inputHeader">
        <p id="inputHeaderTitle"></p>
        <span id="inputHeaderEdit" class="glyphicon glyphicon-pencil">
            <div class="button">
            <button type="button" id="slide_start_button" value="Start" class="btnleft" @click="goBack">Retour</button>
            </div>
        </span>
        </div>
        <div class="space"></div>
        <div class="card mb-4">
            <div>
                <div class="card-header pb-0">
                    <div v-if="errors.length" class="alert alert-danger">
                        <b>Veuillez corriger les erreurs suivantes:</b>
                        <ul>
                        <li v-for="(error, index) in errors" :key="index">{{ error }}</li>
                        </ul>
                    </div>
                    <div v-if="responseData.error == false" :class="stats.icons.succesClassCard" role="alert">
                        <div>
                        <i :class="stats.icons.succesClass"></i>  {{ responseData.message }}
                        </div>
                    </div>
                    <div v-if="responseData.error == true" :class="stats.icons.alertClassCard" role="alert">
                        <div>
                        <i :class="stats.icons.alertClass"></i>  {{ responseData.message }}
                        </div>
                    </div>
                </div>
            </div>
        <div>
    </div>
    <div class="py-4 container-fluid">
        <div class="mt-4 row">
          <div class="col-12">
            <ul class="tabs-choosee">
              <li :class="[activeTab === '1' ? 'active' : '']"><i style='font-size:24px' class='fas'>&#xf1b9;</i> Modèle</li>
            </ul>
            <img v-if="spinner" class="spinner"
                src="@/assets/img/spinner.gif"
                alt="spinner"
            />
            <div class="tabs-content">
                <div class="content-one">
                    <div class="row">
                        <div class="col-md-3">
                            <label for="example-text-input" class="form-control-label">{{ data.vehicule.TypeApprovalNumberVariableLibelle }} <span style="color: red;">*</span></label>
                            <input class="form-control" type="text" v-model="data.vehicule.TypeApprovalNumberVariableData"/>
                        </div>
                        <div class="col-md-3">
                            <label for="example-text-input" class="form-control-label">{{ data.vehicule.typ_dossLibelle }} <span style="color: red;">*</span></label>
                            <input class="form-control" type="text" v-model="data.vehicule.typ_dossData"/>
                        </div>
                        <div class="col-md-3">
                            <label for="example-text-input" class="form-control-label">{{ data.vehicule.VariantVariableLibelle }}<span style="color: red;">*</span></label>
                            <input class="form-control" type="text" v-model="data.vehicule.VariantVariableData"/>
                        </div>
                        <div class="col-md-3">
                            <label for="example-text-input" class="form-control-label">{{ data.vehicule.VersionVariableLibelle }} <span style="color: red;">*</span></label>
                            <input class="form-control" type="text" v-model="data.vehicule.VersionVariableData"/>
                        </div>
                        <div class="col-md-3">
                            <label for="example-text-input" class="form-control-label">{{ data.vehicule.typ_vehLibelle }} <span style="color: red;">*</span></label>
                            <input class="form-control" type="text" v-model="data.vehicule.typ_vehData"/>
                        </div>
                        <div class="col-md-3">
                            <label for="example-text-input" class="form-control-label">{{ data.vehicule.MakeVariableLibelle }} <span style="color: red;">*</span></label>
                            <input class="form-control" type="text" v-model="data.vehicule.MakeVariableData" />
                        </div>
                        <div class="col-md-3">
                            <label for="example-text-input" class="form-control-label">{{ data.vehicule.CommercialNameVariableLibelle }} <span style="color: red;">*</span></label>
                            <input class="form-control" type="text" v-model="data.vehicule.CommercialNameVariableData"/>
                        </div>
                        <div class="col-md-3">
                            <label for="example-text-input" class="form-control-label">{{ data.vehicule.StageOfCompletionCodeVariableLibelle }} <span style="color: red;">*</span></label>
                            <input class="form-control" type="text" v-model="data.vehicule.StageOfCompletionCodeVariableData"/>
                        </div>
                        <div class="col-md-3">
                            <label for="example-text-input" class="form-control-label">{{ data.vehicule.VehicleCategoryCodeVariableLibelle }} <span style="color: red;">*</span></label>
                            <input class="form-control" type="text" v-model="data.vehicule.VehicleCategoryCodeVariableData"/>
                        </div>
                        <div class="col-md-3">
                            <label for="example-text-input" class="form-control-label">{{ data.vehicule.TypeApprovalTypeCodeVariableLibelle }} <span style="color: red;">*</span></label>
                            <input class="form-control" type="text" v-model="data.vehicule.TypeApprovalTypeCodeVariableData"/>
                        </div>
                        <div class="col-md-3">
                            <label for="example-text-input" class="form-control-label">{{ data.vehicule.NumberOfTheMemberStateVariableLibelle }} </label>
                            <input class="form-control" type="text" v-model="data.vehicule.NumberOfTheMemberStateVariableData"/>
                        </div>
                        <div class="col-md-3">
                            <label for="example-text-input" class="form-control-label">{{ data.vehicule.RevisionDateVariableLibelle }} </label>
                            <input class="form-control" type="datetime-local" v-model="data.vehicule.RevisionDateVariableData"/>
                            <!-- <input class="form-control" type="datetime" v-model="data.vehicule.RevisionDateVariableData"/> -->
                        </div>
                        <div class="col-md-3">
                            <label for="example-text-input" class="form-control-label">{{ data.vehicule.LocOfTheStatutoryPlatesCodeVariableLibelle }} </label>
                            <input class="form-control" type="text" v-model="data.vehicule.LocOfTheStatutoryPlatesCodeVariableData"/>
                        </div>
                        <div class="col-md-3">
                            <label for="example-text-input" class="form-control-label">{{ data.vehicule.MethodOfAttachmStatPlatesCodeVariableLibelle }} </label>
                            <input class="form-control" type="text" v-model="data.vehicule.MethodOfAttachmStatPlatesCodeVariableData"/>
                        </div>
                        <div class="col-md-3">
                            <label for="example-text-input" class="form-control-label">{{ data.vehicule.LocationOfTheVinCodeVariableLibelle }} </label>
                            <input class="form-control" type="text" v-model="data.vehicule.LocationOfTheVinCodeVariableData"/>
                        </div>
                        <div class="col-md-3">
                            <label for="example-text-input" class="form-control-label">{{ data.vehicule.TypeApprovalDateOfIssueVariableLibelle }}</label>
                            <input class="form-control" type="datetime" v-model="data.vehicule.TypeApprovalDateOfIssueVariableData"/>
                        </div>
                        <div class="col-md-3">
                            <label for="example-text-input" class="form-control-label">{{ data.vehicule.RightLeftHandTrafficCodeVariableLibelle }}</label>
                            <input class="form-control" type="text" v-model="data.vehicule.RightLeftHandTrafficCodeVariableData"/>
                        </div>
                        <div class="col-md-3">
                            <label for="example-text-input" class="form-control-label">{{ data.vehicule.MetricImperialSpeedometerCodeVariableLibelle }} </label>
                            <input class="form-control" type="text" v-model="data.vehicule.MetricImperialSpeedometerCodeVariableData"/>
                        </div>
                        <div class="col-md-3">
                            <label for="example-text-input" class="form-control-label">{{ data.vehicule.NumberOfAxlesVariableLibelle }}</label>
                            <input class="form-control" type="text" v-model="data.vehicule.NumberOfAxlesVariableData"/>
                        </div>
                        <div class="col-md-3">
                            <label for="example-text-input" class="form-control-label">{{ data.vehicule.NumberOfWheelsVariableLibelle }}</label>
                            <input class="form-control" type="text" v-model="data.vehicule.NumberOfWheelsVariableData"/>
                        </div>


                        <div class="col-md-3">
                            <label for="example-text-input" class="form-control-label">{{ data.vehicule.NumberOfPoweredAxlesVariableLibelle }}</label>
                            <input class="form-control" type="text" v-model="data.vehicule.NumberOfPoweredAxlesVariableData"/>
                        </div>
                        <div class="col-md-3">
                            <label for="example-text-input" class="form-control-label">{{ data.vehicule.WheelbaseVariableLibelle }}</label>
                            <input class="form-control" type="text" v-model="data.vehicule.WheelbaseVariableData"/>
                        </div>
                        <div class="col-md-3">
                            <label for="example-text-input" class="form-control-label">{{ data.vehicule.LengthVariableLibelle }}</label>
                            <input class="form-control" type="text" v-model="data.vehicule.LengthVariableData"/>
                        </div>
                        <div class="col-md-3">
                            <label for="example-text-input" class="form-control-label">{{ data.vehicule.WidthVariableLibelle }}</label>
                            <input class="form-control" type="text" v-model="data.vehicule.WidthVariableData"/>
                        </div>
                        <div class="col-md-3">
                            <label for="example-text-input" class="form-control-label">{{ data.vehicule.HeightVaribaleLibelle }}</label>
                            <input class="form-control" type="text" v-model="data.vehicule.HeightVaribaleData"/>
                        </div>
                        <div class="col-md-3">
                            <label for="example-text-input" class="form-control-label">{{ data.vehicule.MassOfTheVehicleInRunningOrderVariableLibelle }}</label>
                            <input class="form-control" type="text" v-model="data.vehicule.MassOfTheVehicleInRunningOrderVariableData"/>
                        </div>
                        <div class="col-md-3">
                            <label for="example-text-input" class="form-control-label">{{ data.vehicule.ActualMassOfTheVehicleVariableLibelle }}</label>
                            <input class="form-control" type="text" v-model="data.vehicule.ActualMassOfTheVehicleVariableData"/>
                        </div>
                        <div class="col-md-3">
                            <label for="example-text-input" class="form-control-label">{{ data.vehicule.TechnPermMaxLadenMassVariableLibelle }}</label>
                            <input class="form-control" type="text" v-model="data.vehicule.TechnPermMaxLadenMassVariableData"/>
                        </div>
                        <div class="col-md-3">
                            <label for="example-text-input" class="form-control-label">{{ data.vehicule.NumberOfDoorsVariableLibelle }}</label>
                            <input class="form-control" type="text" v-model="data.vehicule.NumberOfDoorsVariableData"/>
                        </div>
                        <div class="col-md-3">
                            <label for="example-text-input" class="form-control-label">{{ data.vehicule.NrOfSeatingPositionsVariableLibelle }}</label>
                            <input class="form-control" type="text" v-model="data.vehicule.NrOfSeatingPositionsVariableData"/>
                        </div>
                        <div class="col-md-3">
                            <label for="example-text-input" class="form-control-label">{{ data.vehicule.IntendedCountryOfRegistrCodeVariableLibelle }}</label>
                            <input class="form-control" type="text" v-model="data.vehicule.IntendedCountryOfRegistrCodeVariableData"/>
                        </div>
                        <div class="col-md-3">
                            <label for="example-text-input" class="form-control-label">{{ data.vehicule.VersionDateIVIVariableLibelle }}</label>
                            <input class="form-control" type="text" v-model="data.vehicule.VersionDateIVIVariableData"/>
                        </div>
                        <div class="col-md-3">
                            <label for="example-text-input" class="form-control-label">{{ data.vehicule.VehicleFittedWithEcoInnovIndVariableLibelle }}</label>
                            <input class="form-control" type="text" v-model="data.vehicule.VehicleFittedWithEcoInnovIndVariableData"/>
                        </div>
                        <div class="col-md-3">
                            <label for="example-text-input" class="form-control-label">{{ data.vehicule.AllowedEuropeanMarketIndicatorVariableLibelle }}</label>
                            <input class="form-control" type="text" v-model="data.vehicule.AllowedEuropeanMarketIndicatorVariableData"/>
                        </div>
                        <div class="col-md-3">
                            <label for="example-text-input" class="form-control-label">{{ data.vehicule.TwentyFourGHzRadarIndVariableLibelle }}</label>
                            <input class="form-control" type="text" v-model="data.vehicule.TwentyFourGHzRadarIndVariableData"/>
                        </div>
                        <div class="col-md-3">
                            <label for="example-text-input" class="form-control-label">{{ data.vehicule.PureElectricVehIndicatorVariableLibelle }}</label>
                            <input class="form-control" type="text" v-model="data.vehicule.PureElectricVehIndicatorVariableData"/>
                        </div>
                        <div class="col-md-3">
                            <label for="example-text-input" class="form-control-label">{{ data.vehicule.DateOfSignatureVariableLibelle }}</label>
                            <input class="form-control" type="text" v-model="data.vehicule.DateOfSignatureVariableData"/>
                        </div>
                        <div class="col-md-3">
                            <label for="example-text-input" class="form-control-label">{{ data.vehicule.NameVariableLibelle }}</label>
                            <input class="form-control" type="text" v-model="data.vehicule.NameVariableData"/>
                        </div>
                        <div class="col-md-3">
                            <label for="example-text-input" class="form-control-label">{{ data.vehicule.AddressTypeCodeVariableLibelle }}</label>
                            <input class="form-control" type="text" v-model="data.vehicule.AddressTypeCodeVariableData"/>
                        </div>
                        <div class="col-md-3">
                            <label for="example-text-input" class="form-control-label">{{ data.vehicule.CodeForBodyworkVariableLibelle }}</label>
                            <input class="form-control" type="text" v-model="data.vehicule.CodeForBodyworkVariableData"/>
                        </div>
                        <div class="col-md-3">
                            <label for="example-text-input" class="form-control-label">{{ data.vehicule.AxleTrackVariable1Libelle }}</label>
                            <input class="form-control" type="text" v-model="data.vehicule.AxleTrackVariable1Data"/>
                        </div>
                        <div class="col-md-3">
                            <label for="example-text-input" class="form-control-label">{{ data.vehicule.AxleTrackVariable2Libelle }}</label>
                            <input class="form-control" type="text" v-model="data.vehicule.AxleTrackVariable2Data"/>
                        </div>
                        <div class="col-md-3">
                            <label for="example-text-input" class="form-control-label">{{ data.vehicule.DistrOfMassRunningOrderAxleVariable1Libelle }}</label>
                            <input class="form-control" type="text" v-model="data.vehicule.DistrOfMassRunningOrderAxleVariable1Data"/>
                        </div>
                        <div class="col-md-3">
                            <label for="example-text-input" class="form-control-label">{{ data.vehicule.DistrOfMassRunningOrderAxleVariable2Libelle }}</label>
                            <input class="form-control" type="text" v-model="data.vehicule.DistrOfMassRunningOrderAxleVariable2Data"/>
                        </div>
                        <div class="col-md-3">
                            <label for="example-text-input" class="form-control-label">{{ data.vehicule.TechnicallyPermMassAxleVariable1Libelle }}</label>
                            <input class="form-control" type="text" v-model="data.vehicule.TechnicallyPermMassAxleVariable1Data"/>
                        </div>
                        <div class="col-md-3">
                            <label for="example-text-input" class="form-control-label">{{ data.vehicule.TechnicallyPermMassAxleVariable2Libelle }}</label>
                            <input class="form-control" type="text" v-model="data.vehicule.TechnicallyPermMassAxleVariable2Data"/>
                        </div>
                        <div class="col-md-3">
                            <label for="example-text-input" class="form-control-label">{{ data.vehicule.RoadLoadCoefF0WLTPVariableLibelle }}</label>
                            <input class="form-control" type="text" v-model="data.vehicule.RoadLoadCoefF0WLTPVariableData"/>
                        </div>
                        <div class="col-md-3">
                            <label for="example-text-input" class="form-control-label">{{ data.vehicule.RoadLoadCoefF1WLTPVariableLibelle }}</label>
                            <input class="form-control" type="text" v-model="data.vehicule.RoadLoadCoefF1WLTPVariableData"/>
                        </div>
                        <div class="col-md-3">
                            <label for="example-text-input" class="form-control-label">{{ data.vehicule.RoadLoadCoefF2WLTPVariableLibelle }}</label>
                            <input class="form-control" type="text" v-model="data.vehicule.RoadLoadCoefF2WLTPVariableData"/>
                        </div>
                        <div class="col-md-3">
                            <label for="example-text-input" class="form-control-label">{{ data.vehicule.ManufacturerOfTheEngineVariableLibelle }}</label>
                            <input class="form-control" type="text" v-model="data.vehicule.ManufacturerOfTheEngineVariableData"/>
                        </div>
                        <div class="col-md-3">
                            <label for="example-text-input" class="form-control-label">{{ data.vehicule.EngineCodeAsMarkedOnTheEngineVariableLibelle }}</label>
                            <input class="form-control" type="text" v-model="data.vehicule.EngineCodeAsMarkedOnTheEngineVariableData"/>
                        </div>
                        <div class="col-md-3">
                            <label for="example-text-input" class="form-control-label">{{ data.vehicule.WorkingPrincipleCodeVariableLibelle }}</label>
                            <input class="form-control" type="text" v-model="data.vehicule.WorkingPrincipleCodeVariableData"/>
                        </div>
                        <div class="col-md-3">
                            <label for="example-text-input" class="form-control-label">{{ data.vehicule.ElectricEngineIndicatorVariableLibelle }}</label>
                            <input class="form-control" type="text" v-model="data.vehicule.ElectricEngineIndicatorVariableData"/>
                        </div>
                        <div class="col-md-3">
                            <label for="example-text-input" class="form-control-label">{{ data.vehicule.ElectricEngineIndicatorVariableLibelle }}</label>
                            <input class="form-control" type="text" v-model="data.vehicule.ElectricEngineIndicatorVariableLibelle"/>
                        </div>
                        <div class="col-md-3">
                            <label for="example-text-input" class="form-control-label">{{ data.vehicule.MaximumContinuousRatedPowerVariableLibelle }}</label>
                            <input class="form-control" type="text" v-model="data.vehicule.MaximumContinuousRatedPowerVariableData"/>
                        </div>
                        <div class="col-md-3">
                            <label for="example-text-input" class="form-control-label">{{ data.vehicule.MaximumSpeedVariableLibelle }}</label>
                            <input class="form-control" type="text" v-model="data.vehicule.MaximumSpeedVariableData"/>
                        </div>
                        <div class="col-md-3">
                            <label for="example-text-input" class="form-control-label">{{ data.vehicule.SoundLevelDriveByVariableLibelle }}</label>
                            <input class="form-control" type="text" v-model="data.vehicule.SoundLevelDriveByVariableData"/>
                        </div>
                        <div class="col-md-3">
                            <label for="example-text-input" class="form-control-label">{{ data.vehicule.ExhaustEmissionLevelEuroVariableLibelle }}</label>
                            <input class="form-control" type="text" v-model="data.vehicule.ExhaustEmissionLevelEuroVariableData"/>
                        </div>
                        <div class="col-md-3">
                            <label for="example-text-input" class="form-control-label">{{ data.vehicule.ElectricEnergyConsPureElectricVariableLibelle }}</label>
                            <input class="form-control" type="text" v-model="data.vehicule.ElectricEnergyConsPureElectricVariableData"/>
                        </div>
                        <div class="col-md-3">
                            <label for="example-text-input" class="form-control-label">{{ data.vehicule.ElectricRangeVariableLibelle }}</label>
                            <input class="form-control" type="text" v-model="data.vehicule.ElectricRangeVariableData"/>
                        </div>
                        <div class="col-md-3">
                            <label for="example-text-input" class="form-control-label">{{ data.vehicule.TrailerBrakeConnectionsCodeVariableLibelle }}</label>
                            <input class="form-control" type="text" v-model="data.vehicule.TrailerBrakeConnectionsCodeVariableData"/>
                        </div>
                    </div>
                </div>
                <br>
                <div class="button">
                    <div v-if="this.$store.getters.new_model">
                        <button type="button" id="slide_start_button" value="Start" class="btnright" v-on:click.prevent="update()">Mettre à jour</button>
                    </div>
                    <div v-else>
                        <button type="button" id="slide_start_button" value="Start" class="btnright" v-on:click.prevent="submit()">Enregistrer</button>
                    </div>
                </div>
              </div>

              

            </div>
          </div>
        </div>
    </div>
  </div> 
        
</template>

<script>
import axios from 'axios';
export default {
    name: 'Model',
    props: ['modele_id','folder_number', 'TypeApprovalNumberVariable'],
    data(){
        return{
            activeTab: "1",
            data: {
                folder_number: "",
                vehicule: {
                    modele_id : "",
                    TypeApprovalNumberVariableLibelle : "",
                    TypeApprovalNumberVariableData : "",
                    typ_dossLibelle : "",
                    typ_dossData : "",
                    VariantVariableLibelle : "",
                    VariantVariableData : "",
                    VersionVariableLibelle : "",
                    VersionVariableData : "",
                    typ_vehLibelle : "",
                    typ_vehData : "",
                    MakeVariableLibelle : "",
                    MakeVariableData : "",
                    CommercialNameVariableLibelle : "",
                    CommercialNameVariableData : "",
                    StageOfCompletionCodeVariableLibelle : "",
                    StageOfCompletionCodeVariableData : "",
                    VehicleCategoryCodeVariableLibelle : "",
                    VehicleCategoryCodeVariableData : "",
                    TypeApprovalTypeCodeVariableLibelle : "",
                    TypeApprovalTypeCodeVariableData : "",
                    NumberOfTheMemberStateVariableLibelle : "",
                    NumberOfTheMemberStateVariableData : "",
                    RevisionDateVariableLibelle : "",
                    RevisionDateVariableData : "",
                    LocOfTheStatutoryPlatesCodeVariableLibelle : "",
                    LocOfTheStatutoryPlatesCodeVariableData : "",
                    MethodOfAttachmStatPlatesCodeVariableLibelle : "",
                    MethodOfAttachmStatPlatesCodeVariableData : "",
                    LocationOfTheVinCodeVariableLibelle : "",
                    LocationOfTheVinCodeVariableData : "",
                    TypeApprovalDateOfIssueVariableLibelle : "",
                    TypeApprovalDateOfIssueVariableData : "",
                    RightLeftHandTrafficCodeVariableLibelle : "",
                    RightLeftHandTrafficCodeVariableData : "",
                    MetricImperialSpeedometerCodeVariableLibelle : "",
                    MetricImperialSpeedometerCodeVariableData : "",
                    NumberOfAxlesVariableLibelle : "",
                    NumberOfAxlesVariableData : "",
                    NumberOfWheelsVariableLibelle : "",
                    NumberOfWheelsVariableData : "",
                    NumberOfPoweredAxlesVariableLibelle : "",
                    NumberOfPoweredAxlesVariableData : "",
                    WheelbaseVariableLibelle : "",
                    WheelbaseVariableData : "",
                    LengthVariableLibelle : "",
                    LengthVariableData : "",
                    WidthVariableLibelle : "",
                    WidthVariableData : "",
                    HeightVaribaleLibelle : "",
                    HeightVaribaleData : "",
                    MassOfTheVehicleInRunningOrderVariableLibelle : "",
                    MassOfTheVehicleInRunningOrderVariableData : "",
                    ActualMassOfTheVehicleVariableLibelle : "",
                    ActualMassOfTheVehicleVariableData : "",
                    TechnPermMaxLadenMassVariableLibelle : "",
                    TechnPermMaxLadenMassVariableData : "",
                    NumberOfDoorsVariableLibelle : "",
                    NumberOfDoorsVariableData : "",
                    NrOfSeatingPositionsVariableLibelle : "",
                    NrOfSeatingPositionsVariableData : "",
                    IntendedCountryOfRegistrCodeVariableLibelle : "",
                    IntendedCountryOfRegistrCodeVariableData : "",
                    VersionDateIVIVariableLibelle : "",
                    VersionDateIVIVariableData : "",
                    VehicleFittedWithEcoInnovIndVariableLibelle : "",
                    VehicleFittedWithEcoInnovIndVariableData : "",
                    AllowedEuropeanMarketIndicatorVariableLibelle : "",
                    AllowedEuropeanMarketIndicatorVariableData : "",
                    TwentyFourGHzRadarIndVariableLibelle : "",
                    TwentyFourGHzRadarIndVariableData : "",
                    PureElectricVehIndicatorVariableLibelle : "",
                    PureElectricVehIndicatorVariableData : "",
                    DateOfSignatureVariableLibelle : "",
                    DateOfSignatureVariableData : "",
                    NameVariableLibelle : "",
                    NameVariableData : "",
                    AddressTypeCodeVariableLibelle : "",
                    AddressTypeCodeVariableData : "",
                    CodeForBodyworkVariableLibelle : "",
                    CodeForBodyworkVariableData : "",
                    AxleTrackVariable1Libelle : "",
                    AxleTrackVariable1Data : "",
                    AxleTrackVariable2Libelle : "",
                    AxleTrackVariable2Data : "",
                    DistrOfMassRunningOrderAxleVariable1Libelle : "",
                    DistrOfMassRunningOrderAxleVariable1Data : "",
                    DistrOfMassRunningOrderAxleVariable2Libelle : "",
                    DistrOfMassRunningOrderAxleVariable2Data : "",
                    TechnicallyPermMassAxleVariable1Libelle : "",
                    TechnicallyPermMassAxleVariable1Data : "",
                    TechnicallyPermMassAxleVariable2Libelle : "",
                    TechnicallyPermMassAxleVariable2Data : "",
                    RoadLoadCoefF0WLTPVariableLibelle : "",
                    RoadLoadCoefF0WLTPVariableData : "",
                    RoadLoadCoefF1WLTPVariableLibelle : "",
                    RoadLoadCoefF1WLTPVariableData : "",
                    RoadLoadCoefF2WLTPVariableLibelle : "",
                    RoadLoadCoefF2WLTPVariableData : "",
                    ManufacturerOfTheEngineVariableLibelle : "",
                    ManufacturerOfTheEngineVariableData : "",
                    EngineCodeAsMarkedOnTheEngineVariableLibelle : "",
                    EngineCodeAsMarkedOnTheEngineVariableData : "",
                    WorkingPrincipleCodeVariableLibelle : "",
                    WorkingPrincipleCodeVariableData : "",
                    ElectricEngineIndicatorVariableLibelle : "",
                    ElectricEngineIndicatorVariableData : "",
                    MaximumContinuousRatedPowerVariableLibelle : "",
                    MaximumContinuousRatedPowerVariableData : "",
                    MaximumSpeedVariableLibelle : "",
                    MaximumSpeedVariableData : "",
                    SoundLevelDriveByVariableLibelle : "",
                    SoundLevelDriveByVariableData : "",
                    ExhaustEmissionLevelEuroVariableLibelle : "",
                    ExhaustEmissionLevelEuroVariableData : "",
                    ElectricEnergyConsPureElectricVariableLibelle : "",
                    ElectricEnergyConsPureElectricVariableData : "",
                    ElectricRangeVariableLibelle : "",
                    ElectricRangeVariableData : "",
                    TrailerBrakeConnectionsCodeVariableLibelle : "",
                    TrailerBrakeConnectionsCodeVariableData : "",
                }
            },
            errors: [],
            spinner: false,
            stats: {
                supprimer: {
                dropDownClass: "dropdown-item d-flex justify-content-between align-items-center",
                titleClass: "me-auto",
                iconClass: "fas fa-solid fa-trash",
                style: "cursor: default;",
                },
                afficher: {
                dropDownClass: "dropdown-item d-flex justify-content-between align-items-center",
                titleClass: "me-auto",
                iconClass: "fas fa-regular fa-eye",
                style: "cursor: default;",
                },
                icons: {
                alertClass : '',
                alertClassCard : '',
                succesClass : '',
                succesClassCard : '',
                }
            },
            responseData: {
                message: '',
                error: null
            }
        }
    },
    created() {
        if(this.$store.getters.new_model) {
            let formData = new FormData();
            formData.append('medeleId', this.$store.getters.new_model);
            axios({method: 'POST', url: 'get_modele',
                headers: {
                "Content-Type": "multipart/form-data"
                },
                data : formData
            }).then(response => {
                //vehicule
                this.data.vehicule.modele_id = response.data.model_id;
                this.data.vehicule.TypeApprovalNumberVariableLibelle = response.data.TypeApprovalNumberVariable.Libelle;
                this.data.vehicule.TypeApprovalNumberVariableData = response.data.TypeApprovalNumberVariable.value;
                this.data.vehicule.typ_dossLibelle = response.data.typ_doss.Libelle;
                this.data.vehicule.typ_dossData = response.data.typ_doss.value;
                this.data.vehicule.VariantVariableLibelle = response.data.VariantVariable.Libelle;
                this.data.vehicule.VariantVariableData = response.data.VariantVariable.value;
                this.data.vehicule.VersionVariableLibelle = response.data.VersionVariable.Libelle;
                this.data.vehicule.VersionVariableData = response.data.VersionVariable.value;

                this.data.vehicule.typ_vehLibelle = response.data.typ_veh.Libelle;
                this.data.vehicule.typ_vehData = response.data.typ_veh.value;

                this.data.vehicule.MakeVariableLibelle = response.data.MakeVariable.Libelle;
                this.data.vehicule.MakeVariableData = response.data.MakeVariable.value;

                this.data.vehicule.CommercialNameVariableLibelle = response.data.CommercialNameVariable.Libelle;
                this.data.vehicule.CommercialNameVariableData = response.data.CommercialNameVariable.value;

                this.data.vehicule.StageOfCompletionCodeVariableLibelle = response.data.StageOfCompletionCodeVariable.Libelle;
                this.data.vehicule.StageOfCompletionCodeVariableData = response.data.StageOfCompletionCodeVariable.value;

                this.data.vehicule.VehicleCategoryCodeVariableLibelle = response.data.VehicleCategoryCodeVariable.Libelle;
                this.data.vehicule.VehicleCategoryCodeVariableData = response.data.VehicleCategoryCodeVariable.value;

                this.data.vehicule.TypeApprovalTypeCodeVariableLibelle = response.data.TypeApprovalTypeCodeVariable.Libelle;
                this.data.vehicule.TypeApprovalTypeCodeVariableData = response.data.TypeApprovalTypeCodeVariable.value;

                this.data.vehicule.NumberOfTheMemberStateVariableLibelle = response.data.NumberOfTheMemberStateVariable.Libelle;
                this.data.vehicule.NumberOfTheMemberStateVariableData = response.data.NumberOfTheMemberStateVariable.value;

                this.data.vehicule.RevisionDateVariableLibelle = response.data.RevisionDateVariable.Libelle;
                this.data.vehicule.RevisionDateVariableData = response.data.RevisionDateVariable.value;

                this.data.vehicule.LocOfTheStatutoryPlatesCodeVariableLibelle = response.data.LocOfTheStatutoryPlatesCodeVariable.Libelle;
                this.data.vehicule.LocOfTheStatutoryPlatesCodeVariableData = response.data.LocOfTheStatutoryPlatesCodeVariable.value;

                this.data.vehicule.MethodOfAttachmStatPlatesCodeVariableLibelle = response.data.MethodOfAttachmStatPlatesCodeVariable.Libelle;
                this.data.vehicule.MethodOfAttachmStatPlatesCodeVariableData = response.data.MethodOfAttachmStatPlatesCodeVariable.value;

                this.data.vehicule.LocationOfTheVinCodeVariableLibelle = response.data.LocationOfTheVinCodeVariable.Libelle;
                this.data.vehicule.LocationOfTheVinCodeVariableData = response.data.LocationOfTheVinCodeVariable.value;

                this.data.vehicule.TypeApprovalDateOfIssueVariableLibelle = response.data.TypeApprovalDateOfIssueVariable.Libelle;
                this.data.vehicule.TypeApprovalDateOfIssueVariableData = response.data.TypeApprovalDateOfIssueVariable.value;

                this.data.vehicule.RightLeftHandTrafficCodeVariableLibelle = response.data.RightLeftHandTrafficCodeVariable.Libelle;
                this.data.vehicule.RightLeftHandTrafficCodeVariableData = response.data.RightLeftHandTrafficCodeVariable.value;

                this.data.vehicule.MetricImperialSpeedometerCodeVariableLibelle = response.data.MetricImperialSpeedometerCodeVariable.Libelle;
                this.data.vehicule.MetricImperialSpeedometerCodeVariableData = response.data.MetricImperialSpeedometerCodeVariable.value;

                this.data.vehicule.NumberOfAxlesVariableLibelle = response.data.NumberOfAxlesVariable.Libelle;
                this.data.vehicule.NumberOfAxlesVariableData = response.data.NumberOfAxlesVariable.value;

                this.data.vehicule.NumberOfWheelsVariableLibelle = response.data.NumberOfWheelsVariable.Libelle;
                this.data.vehicule.NumberOfWheelsVariableData = response.data.NumberOfWheelsVariable.value;

                this.data.vehicule.NumberOfPoweredAxlesVariableLibelle = response.data.NumberOfPoweredAxlesVariable.Libelle;
                this.data.vehicule.NumberOfPoweredAxlesVariableData = response.data.NumberOfPoweredAxlesVariable.value;

                this.data.vehicule.WheelbaseVariableLibelle = response.data.WheelbaseVariable.Libelle;
                this.data.vehicule.WheelbaseVariableData = response.data.WheelbaseVariable.value;

                this.data.vehicule.LengthVariableLibelle = response.data.LengthVariable.Libelle;
                this.data.vehicule.LengthVariableData = response.data.LengthVariable.value;

                this.data.vehicule.WidthVariableLibelle = response.data.WidthVariable.Libelle;
                this.data.vehicule.WidthVariableData = response.data.WidthVariable.value;

                this.data.vehicule.HeightVaribaleLibelle = response.data.HeightVaribale.Libelle;
                this.data.vehicule.HeightVaribaleData = response.data.HeightVaribale.value;

                this.data.vehicule.MassOfTheVehicleInRunningOrderVariableLibelle = response.data.MassOfTheVehicleInRunningOrderVariable.Libelle;
                this.data.vehicule.MassOfTheVehicleInRunningOrderVariableData = response.data.MassOfTheVehicleInRunningOrderVariable.value;

                this.data.vehicule.ActualMassOfTheVehicleVariableLibelle = response.data.ActualMassOfTheVehicleVariable.Libelle;
                this.data.vehicule.ActualMassOfTheVehicleVariableData = response.data.ActualMassOfTheVehicleVariable.value;

                this.data.vehicule.TechnPermMaxLadenMassVariableLibelle = response.data.TechnPermMaxLadenMassVariable.Libelle;
                this.data.vehicule.TechnPermMaxLadenMassVariableData = response.data.TechnPermMaxLadenMassVariable.value;

                this.data.vehicule.NumberOfDoorsVariableLibelle = response.data.NumberOfDoorsVariable.Libelle;
                this.data.vehicule.NumberOfDoorsVariableData = response.data.NumberOfDoorsVariable.value;

                this.data.vehicule.NrOfSeatingPositionsVariableLibelle = response.data.NrOfSeatingPositionsVariable.Libelle;
                this.data.vehicule.NrOfSeatingPositionsVariableData = response.data.NrOfSeatingPositionsVariable.value;

                this.data.vehicule.IntendedCountryOfRegistrCodeVariableLibelle = response.data.IntendedCountryOfRegistrCodeVariable.Libelle;
                this.data.vehicule.IntendedCountryOfRegistrCodeVariableData = response.data.IntendedCountryOfRegistrCodeVariable.value;

                this.data.vehicule.VersionDateIVIVariableLibelle = response.data.VersionDateIVIVariable.Libelle;
                this.data.vehicule.VersionDateIVIVariableData = response.data.VersionDateIVIVariable.value;

                this.data.vehicule.VehicleFittedWithEcoInnovIndVariableLibelle = response.data.VehicleFittedWithEcoInnovIndVariable.Libelle;
                this.data.vehicule.VehicleFittedWithEcoInnovIndVariableData = response.data.VehicleFittedWithEcoInnovIndVariable.value;

                this.data.vehicule.AllowedEuropeanMarketIndicatorVariableLibelle = response.data.AllowedEuropeanMarketIndicatorVariable.Libelle;
                this.data.vehicule.AllowedEuropeanMarketIndicatorVariableData = response.data.AllowedEuropeanMarketIndicatorVariable.value;

                this.data.vehicule.TwentyFourGHzRadarIndVariableLibelle = response.data.TwentyFourGHzRadarIndVariable.Libelle;
                this.data.vehicule.TwentyFourGHzRadarIndVariableData = response.data.TwentyFourGHzRadarIndVariable.value;

                this.data.vehicule.PureElectricVehIndicatorVariableLibelle = response.data.PureElectricVehIndicatorVariable.Libelle;
                this.data.vehicule.PureElectricVehIndicatorVariableData = response.data.PureElectricVehIndicatorVariable.value;

                this.data.vehicule.DateOfSignatureVariableLibelle = response.data.DateOfSignatureVariable.Libelle;
                this.data.vehicule.DateOfSignatureVariableData = response.data.DateOfSignatureVariable.value;

                this.data.vehicule.NameVariableLibelle = response.data.NameVariable.Libelle;
                this.data.vehicule.NameVariableData = response.data.NameVariable.value;

                this.data.vehicule.AddressTypeCodeVariableLibelle = response.data.AddressTypeCodeVariable.Libelle;
                this.data.vehicule.AddressTypeCodeVariableData = response.data.AddressTypeCodeVariable.value;

                this.data.vehicule.CodeForBodyworkVariableLibelle = response.data.CodeForBodyworkVariable.Libelle;
                this.data.vehicule.CodeForBodyworkVariableData = response.data.CodeForBodyworkVariable.value;

                this.data.vehicule.AxleTrackVariable1Libelle = response.data.AxleTrackVariable1.Libelle;
                this.data.vehicule.AxleTrackVariable1Data = response.data.AxleTrackVariable1.value;

                this.data.vehicule.AxleTrackVariable2Libelle = response.data.AxleTrackVariable2.Libelle;
                this.data.vehicule.AxleTrackVariable2Data = response.data.AxleTrackVariable2.value;

                this.data.vehicule.DistrOfMassRunningOrderAxleVariable1Libelle = response.data.DistrOfMassRunningOrderAxleVariable1.Libelle;
                this.data.vehicule.DistrOfMassRunningOrderAxleVariable1Data = response.data.DistrOfMassRunningOrderAxleVariable1.value;

                this.data.vehicule.DistrOfMassRunningOrderAxleVariable2Libelle = response.data.DistrOfMassRunningOrderAxleVariable2.Libelle;
                this.data.vehicule.DistrOfMassRunningOrderAxleVariable2Data = response.data.DistrOfMassRunningOrderAxleVariable2.value;

                this.data.vehicule.TechnicallyPermMassAxleVariable1Libelle = response.data.TechnicallyPermMassAxleVariable1.Libelle;
                this.data.vehicule.TechnicallyPermMassAxleVariable1Data = response.data.TechnicallyPermMassAxleVariable1.value;

                this.data.vehicule.TechnicallyPermMassAxleVariable2Libelle = response.data.TechnicallyPermMassAxleVariable2.Libelle;
                this.data.vehicule.TechnicallyPermMassAxleVariable2Data = response.data.TechnicallyPermMassAxleVariable2.value;

                this.data.vehicule.RoadLoadCoefF0WLTPVariableLibelle = response.data.RoadLoadCoefF0WLTPVariable.Libelle;
                this.data.vehicule.RoadLoadCoefF0WLTPVariableData = response.data.RoadLoadCoefF0WLTPVariable.value;

                this.data.vehicule.RoadLoadCoefF1WLTPVariableLibelle = response.data.RoadLoadCoefF1WLTPVariable.Libelle;
                this.data.vehicule.RoadLoadCoefF1WLTPVariableData = response.data.RoadLoadCoefF1WLTPVariable.value;

                this.data.vehicule.RoadLoadCoefF2WLTPVariableLibelle = response.data.RoadLoadCoefF2WLTPVariable.Libelle;
                this.data.vehicule.RoadLoadCoefF2WLTPVariableData = response.data.RoadLoadCoefF2WLTPVariable.value;

                this.data.vehicule.ManufacturerOfTheEngineVariableLibelle = response.data.ManufacturerOfTheEngineVariable.Libelle;
                this.data.vehicule.ManufacturerOfTheEngineVariableData = response.data.ManufacturerOfTheEngineVariable.value;

                this.data.vehicule.EngineCodeAsMarkedOnTheEngineVariableLibelle = response.data.EngineCodeAsMarkedOnTheEngineVariable.Libelle;
                this.data.vehicule.EngineCodeAsMarkedOnTheEngineVariableData = response.data.EngineCodeAsMarkedOnTheEngineVariable.value;

                this.data.vehicule.WorkingPrincipleCodeVariableLibelle = response.data.WorkingPrincipleCodeVariable.Libelle;
                this.data.vehicule.WorkingPrincipleCodeVariableData = response.data.WorkingPrincipleCodeVariable.value;

                this.data.vehicule.ElectricEngineIndicatorVariableLibelle = response.data.ElectricEngineIndicatorVariable.Libelle;
                this.data.vehicule.ElectricEngineIndicatorVariableData = response.data.ElectricEngineIndicatorVariable.value;

                this.data.vehicule.MaximumContinuousRatedPowerVariableLibelle = response.data.MaximumContinuousRatedPowerVariable.Libelle;
                this.data.vehicule.MaximumContinuousRatedPowerVariableData = response.data.MaximumContinuousRatedPowerVariable.value;

                this.data.vehicule.MaximumSpeedVariableLibelle = response.data.MaximumSpeedVariable.Libelle;
                this.data.vehicule.MaximumSpeedVariableData = response.data.MaximumSpeedVariable.value;

                this.data.vehicule.SoundLevelDriveByVariableLibelle = response.data.SoundLevelDriveByVariable.Libelle;
                this.data.vehicule.SoundLevelDriveByVariableData = response.data.SoundLevelDriveByVariable.value;

                this.data.vehicule.ExhaustEmissionLevelEuroVariableLibelle = response.data.ExhaustEmissionLevelEuroVariable.Libelle;
                this.data.vehicule.ExhaustEmissionLevelEuroVariableData = response.data.ExhaustEmissionLevelEuroVariable.value;

                this.data.vehicule.ElectricEnergyConsPureElectricVariableLibelle = response.data.ElectricEnergyConsPureElectricVariable.Libelle;
                this.data.vehicule.ElectricEnergyConsPureElectricVariableData = response.data.ElectricEnergyConsPureElectricVariable.value;

                this.data.vehicule.ElectricRangeVariableLibelle = response.data.ElectricRangeVariable.Libelle;
                this.data.vehicule.ElectricRangeVariableData = response.data.ElectricRangeVariable.value;

                this.data.vehicule.TrailerBrakeConnectionsCodeVariableLibelle = response.data.TrailerBrakeConnectionsCodeVariable.Libelle;
                this.data.vehicule.TrailerBrakeConnectionsCodeVariableData = response.data.TrailerBrakeConnectionsCodeVariable.value;
            });
        }else{
            let formData = new FormData();
            axios({method: 'POST', url: 'get_modele',
                headers: {
                "Content-Type": "multipart/form-data"
                },
                data : formData
            }).then(response => {
                //vehicule
                this.data.vehicule.TypeApprovalNumberVariableLibelle = response.data.TypeApprovalNumberVariable.Libelle;
                this.data.vehicule.typ_dossLibelle = response.data.typ_doss.Libelle;
                this.data.vehicule.VariantVariableLibelle = response.data.VariantVariable.Libelle;
                this.data.vehicule.VersionVariableLibelle = response.data.VersionVariable.Libelle;
                this.data.vehicule.typ_vehLibelle = response.data.typ_veh.Libelle;
                this.data.vehicule.MakeVariableLibelle = response.data.MakeVariable.Libelle;
                this.data.vehicule.CommercialNameVariableLibelle = response.data.CommercialNameVariable.Libelle;
                this.data.vehicule.StageOfCompletionCodeVariableLibelle = response.data.StageOfCompletionCodeVariable.Libelle;
                this.data.vehicule.VehicleCategoryCodeVariableLibelle = response.data.VehicleCategoryCodeVariable.Libelle;
                this.data.vehicule.TypeApprovalTypeCodeVariableLibelle = response.data.TypeApprovalTypeCodeVariable.Libelle;                
                this.data.vehicule.NumberOfTheMemberStateVariableLibelle = response.data.NumberOfTheMemberStateVariable.Libelle;
                this.data.vehicule.RevisionDateVariableLibelle = response.data.RevisionDateVariable.Libelle;
                this.data.vehicule.LocOfTheStatutoryPlatesCodeVariableLibelle = response.data.LocOfTheStatutoryPlatesCodeVariable.Libelle;                
                this.data.vehicule.MethodOfAttachmStatPlatesCodeVariableLibelle = response.data.MethodOfAttachmStatPlatesCodeVariable.Libelle;
                this.data.vehicule.LocationOfTheVinCodeVariableLibelle = response.data.LocationOfTheVinCodeVariable.Libelle;
                this.data.vehicule.TypeApprovalDateOfIssueVariableLibelle = response.data.TypeApprovalDateOfIssueVariable.Libelle;
                this.data.vehicule.RightLeftHandTrafficCodeVariableLibelle = response.data.RightLeftHandTrafficCodeVariable.Libelle;
                this.data.vehicule.MetricImperialSpeedometerCodeVariableLibelle = response.data.MetricImperialSpeedometerCodeVariable.Libelle;
                this.data.vehicule.NumberOfAxlesVariableLibelle = response.data.NumberOfAxlesVariable.Libelle;
                this.data.vehicule.NumberOfWheelsVariableLibelle = response.data.NumberOfWheelsVariable.Libelle;
                this.data.vehicule.NumberOfPoweredAxlesVariableLibelle = response.data.NumberOfPoweredAxlesVariable.Libelle;
                this.data.vehicule.WheelbaseVariableLibelle = response.data.WheelbaseVariable.Libelle;
                this.data.vehicule.LengthVariableLibelle = response.data.LengthVariable.Libelle;
                this.data.vehicule.WidthVariableLibelle = response.data.WidthVariable.Libelle;
                this.data.vehicule.HeightVaribaleLibelle = response.data.HeightVaribale.Libelle;
                this.data.vehicule.MassOfTheVehicleInRunningOrderVariableLibelle = response.data.MassOfTheVehicleInRunningOrderVariable.Libelle;
                this.data.vehicule.ActualMassOfTheVehicleVariableLibelle = response.data.ActualMassOfTheVehicleVariable.Libelle;
                this.data.vehicule.TechnPermMaxLadenMassVariableLibelle = response.data.TechnPermMaxLadenMassVariable.Libelle;
                this.data.vehicule.NumberOfDoorsVariableLibelle = response.data.NumberOfDoorsVariable.Libelle;
                this.data.vehicule.NrOfSeatingPositionsVariableLibelle = response.data.NrOfSeatingPositionsVariable.Libelle;
                this.data.vehicule.IntendedCountryOfRegistrCodeVariableLibelle = response.data.IntendedCountryOfRegistrCodeVariable.Libelle;
                this.data.vehicule.VersionDateIVIVariableLibelle = response.data.VersionDateIVIVariable.Libelle;
                this.data.vehicule.VehicleFittedWithEcoInnovIndVariableLibelle = response.data.VehicleFittedWithEcoInnovIndVariable.Libelle;
                this.data.vehicule.AllowedEuropeanMarketIndicatorVariableLibelle = response.data.AllowedEuropeanMarketIndicatorVariable.Libelle;
                this.data.vehicule.TwentyFourGHzRadarIndVariableLibelle = response.data.TwentyFourGHzRadarIndVariable.Libelle;
                this.data.vehicule.PureElectricVehIndicatorVariableLibelle = response.data.PureElectricVehIndicatorVariable.Libelle;
                this.data.vehicule.DateOfSignatureVariableLibelle = response.data.DateOfSignatureVariable.Libelle;
                this.data.vehicule.NameVariableLibelle = response.data.NameVariable.Libelle;
                this.data.vehicule.AddressTypeCodeVariableLibelle = response.data.AddressTypeCodeVariable.Libelle;
                this.data.vehicule.CodeForBodyworkVariableLibelle = response.data.CodeForBodyworkVariable.Libelle;
                this.data.vehicule.AxleTrackVariable1Libelle = response.data.AxleTrackVariable1.Libelle;
                this.data.vehicule.AxleTrackVariable2Libelle = response.data.AxleTrackVariable2.Libelle;
                this.data.vehicule.DistrOfMassRunningOrderAxleVariable1Libelle = response.data.DistrOfMassRunningOrderAxleVariable1.Libelle;
                this.data.vehicule.DistrOfMassRunningOrderAxleVariable2Libelle = response.data.DistrOfMassRunningOrderAxleVariable2.Libelle;
                this.data.vehicule.TechnicallyPermMassAxleVariable1Libelle = response.data.TechnicallyPermMassAxleVariable1.Libelle;
                this.data.vehicule.TechnicallyPermMassAxleVariable2Libelle = response.data.TechnicallyPermMassAxleVariable2.Libelle;
                this.data.vehicule.RoadLoadCoefF0WLTPVariableLibelle = response.data.RoadLoadCoefF0WLTPVariable.Libelle;
                this.data.vehicule.RoadLoadCoefF1WLTPVariableLibelle = response.data.RoadLoadCoefF1WLTPVariable.Libelle;
                this.data.vehicule.RoadLoadCoefF2WLTPVariableLibelle = response.data.RoadLoadCoefF2WLTPVariable.Libelle;
                this.data.vehicule.ManufacturerOfTheEngineVariableLibelle = response.data.ManufacturerOfTheEngineVariable.Libelle;
                this.data.vehicule.EngineCodeAsMarkedOnTheEngineVariableLibelle = response.data.EngineCodeAsMarkedOnTheEngineVariable.Libelle;
                this.data.vehicule.WorkingPrincipleCodeVariableLibelle = response.data.WorkingPrincipleCodeVariable.Libelle;
                this.data.vehicule.ElectricEngineIndicatorVariableLibelle = response.data.ElectricEngineIndicatorVariable.Libelle;
                this.data.vehicule.MaximumContinuousRatedPowerVariableLibelle = response.data.MaximumContinuousRatedPowerVariable.Libelle;
                this.data.vehicule.MaximumSpeedVariableLibelle = response.data.MaximumSpeedVariable.Libelle;
                this.data.vehicule.SoundLevelDriveByVariableLibelle = response.data.SoundLevelDriveByVariable.Libelle;
                this.data.vehicule.ExhaustEmissionLevelEuroVariableLibelle = response.data.ExhaustEmissionLevelEuroVariable.Libelle;
                this.data.vehicule.ElectricEnergyConsPureElectricVariableLibelle = response.data.ElectricEnergyConsPureElectricVariable.Libelle;
                this.data.vehicule.ElectricRangeVariableLibelle = response.data.ElectricRangeVariable.Libelle;
                this.data.vehicule.TrailerBrakeConnectionsCodeVariableLibelle = response.data.TrailerBrakeConnectionsCodeVariable.Libelle;      
            });          
        }
    },
    methods: {
        goBack() {
            this.$router.go(-1)
        },
        formatDate(date) {
            const dateObject = new Date(Date.parse(date));
            return dateObject;
        },
        checkRequiredField(data) {
            this.errors = [];
            if (!data.vehicule.TypeApprovalNumberVariableData) {
            this.errors.push('Champs "'+this.data.vehicule.TypeApprovalNumberVariableLibelle+'" véhicule Obligatoire.');
            }

            if (!data.vehicule.typ_dossData) {
            this.errors.push('Champs "'+this.data.vehicule.typ_dossLibelle+'" véhicule Obligatoire.');
            }

            if (!data.vehicule.VariantVariableData) {
            this.errors.push('Champs "'+this.data.vehicule.VariantVariableLibelle+'" véhicule Obligatoire.');
            }

            if (!data.vehicule.VersionVariableData) {
            this.errors.push('Champs "'+this.data.vehicule.VersionVariableLibelle+'" véhicule Obligatoire.');
            }

            if (!data.vehicule.typ_vehData) {
            this.errors.push('Champs "'+this.data.vehicule.typ_vehLibelle+'" véhicule Obligatoire.');
            }

            if (!data.vehicule.MakeVariableData) {
            this.errors.push('Champs "'+this.data.vehicule.MakeVariableLibelle+'" véhicule Obligatoire.');
            }

            if (!data.vehicule.CommercialNameVariableData) {
            this.errors.push('Champs "'+this.data.vehicule.CommercialNameVariableLibelle+'" véhicule Obligatoire.');
            }

            if (!data.vehicule.StageOfCompletionCodeVariableData) {
            this.errors.push('Champs "'+this.data.vehicule.StageOfCompletionCodeVariableLibelle+'" véhicule Obligatoire.');
            }

            if (!data.vehicule.VehicleCategoryCodeVariableData) {
            this.errors.push('Champs "'+this.data.vehicule.VehicleCategoryCodeVariableLibelle+'" véhicule Obligatoire.');
            }

            if (!data.vehicule.TypeApprovalTypeCodeVariableData) {
            this.errors.push('Champs "'+this.data.vehicule.TypeApprovalTypeCodeVariableLibelle+'" véhicule Obligatoire.');
            }
            //
            if (data.vehicule.NumberOfWheelsVariableData && !Number.isInteger(data.vehicule.NumberOfWheelsVariableData)) {
            this.errors.push('Erreur :Champs "'+this.data.vehicule.NumberOfWheelsVariableLibelle+'" La valeur doit être un nombre entier 0-9.');
            }
            if (data.vehicule.NumberOfPoweredAxlesVariableData && !Number.isInteger(data.vehicule.NumberOfPoweredAxlesVariableData)) {
            this.errors.push('Erreur :Champs "'+this.data.vehicule.NumberOfPoweredAxlesVariableLibelle+'" La valeur doit être un nombre entier 0-9.');
            }
            if (data.vehicule.WheelbaseVariableData && !Number.isInteger(data.vehicule.WheelbaseVariableData)) {
            this.errors.push('Erreur :Champs "'+this.data.vehicule.WheelbaseVariableLibelle+'" La valeur doit être un nombre entier 0-9.');
            }
            if (data.vehicule.LengthVariableData && !Number.isInteger(data.vehicule.LengthVariableData)) {
            this.errors.push('Erreur :Champs "'+this.data.vehicule.LengthVariableLibelle+'" La valeur doit être un nombre entier 0-9.');
            }
            if (data.vehicule.WidthVariableData && !Number.isInteger(data.vehicule.WidthVariableData)) {
            this.errors.push('Erreur :Champs "'+this.data.vehicule.WidthVariableLibelle+'" La valeur doit être un nombre entier 0-9.');
            }
            if (data.vehicule.HeightVaribaleData && !Number.isInteger(data.vehicule.HeightVaribaleData)) {
            this.errors.push('Erreur :Champs "'+this.data.vehicule.HeightVaribaleLibelle+'" La valeur doit être un nombre entier 0-9.');
            }
            if (data.vehicule.MassOfTheVehicleInRunningOrderVariableData && !Number.isInteger(data.vehicule.MassOfTheVehicleInRunningOrderVariableData)) {
            this.errors.push('Erreur :Champs "'+this.data.vehicule.MassOfTheVehicleInRunningOrderVariableLibelle+'" La valeur doit être un nombre entier 0-9.');
            }
            if (data.vehicule.ActualMassOfTheVehicleVariableData && !Number.isInteger(data.vehicule.ActualMassOfTheVehicleVariableData)) {
            this.errors.push('Erreur :Champs "'+this.data.vehicule.ActualMassOfTheVehicleVariableLibelle+'" La valeur doit être un nombre entier 0-9.');
            }
            if (data.vehicule.TechnPermMaxLadenMassVariableData && !Number.isInteger(data.vehicule.TechnPermMaxLadenMassVariableData)) {
            this.errors.push('Erreur :Champs "'+this.data.vehicule.TechnPermMaxLadenMassVariableLibelle+'" La valeur doit être un nombre entier 0-9.');
            }
            if (data.vehicule.NumberOfDoorsVariableData && !Number.isInteger(data.vehicule.NumberOfDoorsVariableData)) {
            this.errors.push('Erreur :Champs "'+this.data.vehicule.NumberOfDoorsVariableLibelle+'" La valeur doit être un nombre entier 0-9.');
            }
            if (data.vehicule.NrOfSeatingPositionsVariableData && !Number.isInteger(data.vehicule.NrOfSeatingPositionsVariableData)) {
            this.errors.push('Erreur :Champs "'+this.data.vehicule.NrOfSeatingPositionsVariableLibelle+'" La valeur doit être un nombre entier 0-9.');
            }
            if (data.vehicule.RoadLoadCoefF0WLTPVariableData && !Number.isInteger(data.vehicule.RoadLoadCoefF0WLTPVariableData)) {
            this.errors.push('Erreur :Champs "'+this.data.vehicule.RoadLoadCoefF0WLTPVariableLibelle+'" La valeur doit être un nombre entier 0-9.');
            }
            if (data.vehicule.ElectricEnergyConsPureElectricVariableData && !Number.isInteger(data.vehicule.ElectricEnergyConsPureElectricVariableData)) {
            this.errors.push('Erreur :Champs "'+this.data.vehicule.ElectricEnergyConsPureElectricVariableLibelle+'" La valeur doit être un nombre entier 0-9.');
            }
            if (data.vehicule.RevisionDateVariableData && !(this.formatDate(data.vehicule.RevisionDateVariableData ) instanceof Date)) {
            this.errors.push('Erreur :Champs "'+this.data.vehicule.ElectricEnergyConsPureElectricVariableLibelle+'" La valeur doit être un nombre entier YYYY-MM-DD.');
            }

            if (!this.errors.length) {
                return true;
            } else {
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth' // for smooth scrolling, not supported in all browsers
                });
                return false;
            }
        },
        submit(){
        this.errors = [];
        let formData = new FormData();
        const dataString = JSON.stringify(this.data);
        formData.append('data', dataString);
        this.spinner = true;
            if(this.checkRequiredField(this.data)) {
                axios({method: 'POST', url: 'create_new_modele',
                headers: {
                    "Content-Type": "multipart/form-data"
                },
                data : formData
                }).then(response => {
                    if(response.data.error) {
                        if(response.data.message) {
                            this.errors.push(response.data.message);
                        } else {
                            this.errors.push("Erreur création");
                        }
                    }else {
                        this.$router.push('models') 
                    }
                    this.spinner = false;
                });
            } else {
                this.spinner = false;
            }
        },
        update(){
            this.errors = [];
            let formData = new FormData();
            const dataString = JSON.stringify(this.data);
            formData.append('data', dataString);
            this.spinner = true;
            if(this.checkRequiredField(this.data)) {
                axios({method: 'POST', url: 'update_model',
                headers: {
                    "Content-Type": "multipart/form-data"
                },
                data : formData
                }).then(response => {
                if(response.data.error) {
                    if(response.data.message) {
                    this.errors.push(response.data.message);
                    } else {
                    this.errors.push("Erreur mise à jour");
                    }
                }
                this.spinner = false;

                this.responseData.error = response.data.error;
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth' // for smooth scrolling, not supported in all browsers
                });
                if(this.responseData.error == false) {
                    this.responseData.message = response.data.message;
                    this.stats.icons.succesClass = "bi bi-check";
                    this.stats.icons.succesClassCard = "alert alert-success d-flex align-items-center";
                    setTimeout(() => {
                    this.responseData.message = null;
                    this.stats.icons.alertClass = null;
                    this.stats.icons.alertClassCard = null;
                    this.responseData.error = null;
                    }, 2500);
                } else {
                    this.responseData.message = response.data.message;
                    this.stats.icons.alertClass = "bi bi-exclamation-triangle";
                    this.stats.icons.alertClassCard = "alert alert-danger d-flex align-items-center";
                    setTimeout(() => {
                    this.responseData.message = null;
                    this.stats.icons.alertClass = null;
                    this.stats.icons.alertClassCard = null;
                    }, 2500);
                }
                });
            }else {
                this.spinner = false;
            }
        }
    }
}
</script>
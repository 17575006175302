<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-lg-12">
        <div class="row">
          <div class="col-lg-3 col-md-6 col-12">
            <a @click="allDossiers()" :style="stats.styles.cursor">
              <card
                :title="stats.money.title"
                :value="num_folders"
                :iconClass="stats.money.iconClass"
                :iconBackground="stats.money.iconBackground"
                directionReverse
              ></card>
            </a>
          </div>
          <div class="col-lg-3 col-md-6 col-12">
            <a @click="filterByValue(['validé'])" :style="stats.styles.cursor">
              <card
                :title="stats.users.title"
                :value="num_folders_validated"
                :iconClass="stats.users.iconClass"
                :iconBackground="stats.users.iconBackground"
                directionReverse
              ></card>
            </a>
          </div>
          <div class="col-lg-3 col-md-6 col-12">
            <a @click="filterByValue(['INCOMPLET','Dupliqué','Date invalide','Fromat EREG invalide'])" :style="stats.styles.cursor">
              <card
                :title="stats.clients.title"
                :value="num_folders_incomplete"
                :iconClass="stats.clients.iconClass"
                :iconBackground="stats.clients.iconBackground"
                :percentageColor="stats.clients.percentageColor"
                directionReverse
              ></card>
            </a>
          </div>
          <div class="col-lg-3 col-md-6 col-12">
            <a @click="filterByValue(['en attente','Prêt pour l\'immatriculation'])" :style="stats.styles.cursor">
              <card
                :title="stats.sales.title"
                :value="num_folders_waiting"
                :iconClass="stats.sales.iconClass"
                :iconBackground="stats.sales.iconBackground"
                directionReverse
              ></card>
            </a>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="p-3 pb-0 card-header">
                <div class="row">
                  <div class="col-6 d-flex align-items-center">
                    <h6 class="mb-0">Liste des dossiers</h6>
                  </div>
                  <div class="col-6 text-end">
                    <button color="dark" variant="gradient" class="newfile" @click="goToCreateNewFolder">
                     <i class="fas fa-plus me-2"></i>
                      Nouveau Dossier
                    </button>
                  </div>
                </div>
              </div>
              <div class="card-body p-3">
                <div class=" row">
                  <div class="col-12">
                    <div class="card">
                      <div class="card-header pb-0 p-3">
                        <div class="row">
                          <div class="col-4 d-flex align-items-center">
                            <div class="input-group">
                              <input
                                type="text"
                                class="form-control"
                                v-model="filter"
                                :placeholder="
                                  'Recherche ...'
                                "
                              />
                              <span class="input-group-text text-body">
                                <i class="fas fa-search" aria-hidden="true"></i>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="card-body px-0 pt-0 pb-2">
                        <div class="table-responsive p-0">
                          <table class="table align-items-center mb-0">
                            <thead>
                              <tr>
                                <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Numéro de dossier</th>
                                <th
                                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Numéro de chassis</th>
                                <th
                                  class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Distributeur</th>
                                  <th
                                  class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">cnit</th>
                                <th
                                  class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Statut</th>
                                <th class="text-secondary opacity-7"></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="folder in filteredRows" :key="folder.folder_id">
                                <td>
                                  <div class="d-flex px-2 py-1">
                                    <div class="d-flex flex-column justify-content-center">
                                      <p class="text-xs font-weight-bold mb-0" v-html="highlightMatches(folder.folder_number)"></p>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <p class="text-xs font-weight-bold mb-0" v-html="highlightMatches(folder.vehicule_identifier)"></p>
                                </td>
                                <td class="align-middle text-center">
                                  <p class="text-xs font-weight-bold mb-0" v-html="highlightMatches(folder.distributer_trade_name)"></p>
                                </td>
                                <td class="align-middle text-center">
                                  <p class="text-xs font-weight-bold mb-0" v-html="highlightMatches(folder.ref_vehicule_cnit)"></p>
                                </td>
                                <td class="align-middle text-center text-sm">
                                  <span class="badge badge-sm" :class="folder.folder_status_color" v-html="highlightMatches(folder.folder_status_lib)"></span>
                                </td>
                                <td class="align-middle">
                                  <div class="dropdownItem">
                                    <a class="dropdown" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                      <i class="fas fa-ellipsis-v"></i>
                                    </a>
                                    <ul class="dropdown-menu">
                                      <li role="presentation">
                                        <a :class="stats.afficher.dropDownClass" @click="openFolder(folder.folder_number)">
                                          <span :class="stats.afficher.titleClass" :style="stats.afficher.style">Afficher</span>
                                          <i :class="stats.afficher.iconClass"></i>
                                        </a>
                                      </li>
                                      <li role="suppression" data-bs-toggle="modal" data-bs-target="#staticBackdrop" >
                                        <a :class="stats.supprimer.dropDownClass" @click="sendFolderId(folder.folder_id)">
                                          <span :class="stats.supprimer.titleClass" :style="stats.supprimer.style">Supprimer</span>
                                          <i :class="stats.supprimer.iconClass"></i>
                                        </a>
                                      </li>
                                    </ul>
                                  </div>
                                  <!-- Modal -->
                                  <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                    <div class="modal-dialog">
                                      <div class="modal-content">
                                        <div class="modal-body">
                                          <div class="d-block text-center">
                                              <i class="bi bi-exclamation-circle"></i>
                                            <p>Êtes-vous sûr de vouloir supprimer ce dossier ?</p>
                                          </div>
                                        </div>
                                        <div class="modal-footer d-block text-center">
                                          <button type="button" class="btn btn-outline-secondary"  data-bs-dismiss="modal">Non</button>
                                          <button type="button" class="btn btn-outline-danger" @click="deleteFolder(folderId)"  data-bs-dismiss="modal">Oui</button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </td>                             
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Card from "@/views/Cards/Card.vue";
import { mapActions, mapMutations } from "vuex";
import axios from 'axios';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import  '@fortawesome/vue-fontawesome';
import 'bootstrap-icons/font/bootstrap-icons.css';

export default {
  name: "dashboard",
  data() {
    return {
      filter:'',
      num_folders : 0,
      num_folders_validated : 0,
      num_folders_waiting : 0,
      num_folders_incomplete : 0,
      folders : [],
      spinner: false,
      folderId: '',
      allDossier: null,
      searchTerm:'',
      valide: '',
      stats: {
        money: {
          title: "Dossiers",
          iconClass: "ni ni-folder-17",
          iconBackground: "bg-gradient-primary",
        },
        users: {
          title: "Dossiers validés",
          iconClass: "ni ni-check-bold",
          iconBackground: "bg-gradient-success",
        },
        clients: {
          title: "Dossiers Incomplet",
          iconClass: "ni ni-notification-70",
          percentageColor: "text-danger",
          iconBackground: "bg-gradient-danger",
        },
        sales: {
          title: "Dossiers En attente",
          iconClass: "ni ni-send",
          iconBackground: "bg-gradient-secondary",
        },
        afficher: {
          dropDownClass: "dropdown-item d-flex justify-content-between align-items-center",
          titleClass: "me-auto",
          iconClass: "fas fa-regular fa-eye",
          style: "cursor: default;",
        },
        supprimer: {
          dropDownClass: "dropdown-item d-flex justify-content-between align-items-center",
          titleClass: "me-auto",
          iconClass: "fas fa-solid fa-trash",
          style: "cursor: default;",
        },
        styles: {
          cursor: "cursor: pointer;",
        },
      }
    };
  },
  created() {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token');
    axios.get('get_folders').then(response => {
      this.num_folders_incomplete = response.data.num_folders_incomplete;
      this.num_folders_validated = response.data.num_folders_validated;
      this.num_folders_waiting = response.data.num_folders_waiting;
      this.num_folders = response.data.num_folders_initialized + this.num_folders_incomplete + this.num_folders_validated + this.num_folders_waiting;
      this.folders = response.data.folders;
    });
  },
  components: {
    Card
  },
  methods: {
    ...mapMutations(["folder_number"]),
    ...mapActions([
      "getFolderNumber",
    ]),
    goToCreateNewFolder() {
      this.folder_number(null);
      this.$router.push('repositories') 
    },
    sendFolderId(id){
      this.folderId = id;
    },
    deleteFolder(folder_id) {
      this.spinner = true;
      let formData = new FormData();
      formData.append('number', folder_id);
      axios({method: 'POST', url: 'delete_folder',
          headers: {
            "Content-Type": "multipart/form-data"
          },
          data : formData
        }).then(response => {
          console.log(response.error)
          window.location.reload();
          this.spinner = false;
        });
    },
    openFolder(folder_number) {
      this.folder_number(folder_number);
      this.$router.push({name: 'Dossiers', params: {folder_number: folder_number}}) 
    },
    highlightMatches(text) {
      const matchExists = text.toLowerCase().includes(this.filter.toLowerCase());
      if (!matchExists) return text;

      const re = new RegExp(this.filter, 'ig');
      return text.replace(re, matchedText => `<strong>${matchedText}</strong>`);
    },
    filterByValue(value) {
      this.valide = value;
      return this.valide;
    },
    allDossiers() {
      this.allDossier = '';
      this.valide = '';
      return this.allDossier;
    },
  },
  computed: {
    filteredRows() {
    return this.folders.filter(row => {
      const folder_numbers = row.folder_number.toString().toLowerCase();
      const vehicule_identifiers = row.vehicule_identifier.toString().toLowerCase();
      const distributer_trade_names = row.distributer_trade_name.toString().toLowerCase();
      const folder_status_libs = row.folder_status_lib.toString().toLowerCase();
      const ref_vehicule_cnits = row.ref_vehicule_cnit.toString().toLowerCase();
      
      if(this.valide){
        let length = this.valide.length;
        var condition = folder_status_libs.includes(this.valide[0].toLowerCase());
        for ( var i = 1; i < length; i++ ) {
          condition = condition || folder_status_libs.includes(this.valide[i].toLowerCase());
        }
        if(this.filter){
          this.searchTerm = this.filter.toLowerCase();
          condition = condition && (folder_numbers.includes(this.searchTerm) || vehicule_identifiers.includes(this.searchTerm) || distributer_trade_names.includes(this.searchTerm) || folder_status_libs.includes(this.searchTerm));
        }

        return condition;
      }else{
        this.searchTerm = this.filter.toLowerCase();
      }

      return folder_numbers.includes(this.searchTerm) || vehicule_identifiers.includes(this.searchTerm) || distributer_trade_names.includes(this.searchTerm) || folder_status_libs.includes(this.searchTerm) || ref_vehicule_cnits.includes(this.searchTerm);
    });
  },
    
  }
};
</script>

<style>

.newfile{
   cursor: pointer;
    outline: 0;
    color: #fff;
    background-color: #404764;
    border-color: red;
    display: inline-block;
    font-weight: 400;
    line-height: 1.5;
    text-align: center;
    border: 1px solid transparent;
    padding: 6px 12px;
    font-size: 16px;
    border-radius: .25rem;
}
.newfile:hover{

    margin-left:10px;   
    cursor: pointer;
    outline: 0;
    display: inline-block;
    font-weight: 400;
    line-height: 1.5;
    text-align: center;
    background-color: #fff;
    border: 1px solid #404764;
    padding: 6px 12px;
    font-size: 1rem;
    border-radius: .25rem;
    color: #404764;
    border-color: #404764;
}
.bi-exclamation-circle{
  font-size:50px;
  color:orange
}

</style>
<template>
  <main class="mt-0 main-content">
    <section>
      <div class="page-header min-vh-100">
        <div class="container">
          <div class="row">
            <div class="mx-auto col-xl-4 col-lg-5 col-md-7 d-flex flex-column mx-lg-0">
              <div class="card card-plain">
                <div class="pb-0 card-header text-start">
                  <h4 class="font-weight-bolder">Connexion</h4>
                  <p class="mb-0">Entez votre email et mot de passe pour se connecter</p>

                  <div v-if="error" class="alert alert-danger">
                    {{ error }}
                  </div>
                </div>
                <div class="card-body">
                  <form role="form">
                    <div class="mb-3">
                      <input type="email" v-model="first" class="form-control">
                    </div>
                    <div class="mb-3">
                      <input type="password" v-model="second" class="form-control">
                    </div>
                    <argon-switch id="rememberMe">Je souhaite rester connecté</argon-switch>

                    <div class="text-center">
                      <argon-button class="mt-4" color="secondary" fullWidth size="lg"
                        @click.prevent="login()">Se connecter</argon-button>
                    </div>
                  </form>
                </div>
                <div class="px-1 pt-0 text-center card-footer px-lg-2">
                  <p class="mx-auto mb-4 text-sm">
                    Vous n'avez pas un compte?
                    <a
                      href="javascript:;"
                      class="text-secondary font-weight-bold"
                    @click="redirectUser()">Créer nouveau compte</a>
                  </p>
                </div>
              </div>
            </div>
            <div class="top-0 my-auto text-center col-6 d-lg-flex d-none h-100 pe-0 position-absolute end-0 justify-content-center flex-column">
              <div
                class="position-relative bg-gradient-primary h-100 m-3 px-7 border-radius-lg d-flex flex-column justify-content-center overflow-hidden"
                :style="{
                  backgroundImage: 'url(' + require('@/assets/img/seres-3_neon.jpg') + ')',
                  backgroundSize: 'cover'}"
                > 
                <span class="mask bg-secondary opacity-8"></span>
                <img class="border-radius-lg position-relative" alt="Seres" src="@/assets/img/logo-eve-transparent.png" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>
<script>
import ArgonSwitch from "@/components/ArgonSwitch.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import router from '@/router/index.js';
const body = document.getElementsByTagName("body")[0];
import axios from 'axios';

export default {
  name: "signin",
  components: {
    ArgonSwitch,
    ArgonButton,
  },
  data() {
    return {
      first : null,
      second : null,
      showSuccessAlert: false,
      error: '',
    }
    
  },
  created() {
    this.$store.state.hideConfigButton = true;
    this.$store.state.showNavbar = false;
    this.$store.state.showSidenav = false;
    this.$store.state.showFooter = false;
    body.classList.remove("bg-gray-100");
  },
  beforeUnmount() {
    this.$store.state.hideConfigButton = false;
    this.$store.state.showNavbar = true;
    this.$store.state.showSidenav = true;
    this.$store.state.showFooter = true;
    body.classList.add("bg-gray-100");
  },
  methods: {
      redirectUser() { // this method is called on button click  
        router.push({name: 'Signup'})         
      },
      async login() {
        let formData = new FormData();
        formData.append('userLogin', this.first);
        formData.append('password', this.second);
        const response = await axios({
          method: 'POST',
          url: 'getToken',
          headers: {
            "Content-Type": "multipart/form-data"
          },
          data : formData
        });
        
        if(response.data.error) {
          this.error = response.data.message;
        } else {
          //Store the token
          localStorage.setItem('token', response.data.token);
          localStorage.setItem('user_id', response.data.user.user_id);
          //this.$store.dispatch('user', response.data.user);
          router.push({name: 'Tableau de bords'});
        }
    },
    
  }
};

</script>
<template>
<TabNav><Tab>{{selected}}</Tab></TabNav>
  <nav aria-label="breadcrumb">
  {{selected}}
    <ol
      class="px-0 pt-1 pb-0 mb-0 bg-transparent breadcrumb"
      :class="`' me-sm-6' ${this.$store.state.isNavFixed ? 'text-dark' : 'text-white' }`"
    >
      <li class="text-sm breadcrumb-item">
        <a
        :class="this.$store.state.isNavFixed ? 'text-dark' : 'text-white'"
         class="opacity-8" href="#">Pages</a>
      </li>
      <li
        class="text-sm breadcrumb-item active"
        :class="this.$store.state.isNavFixed ? 'text-dark' : 'text-white'"
        aria-current="page"
      >
        {{ currentPage }}
      </li>
    </ol>
  </nav>
</template>

<script>
import TabNav from '@/views/components/TabNav.vue';
import Tab from '@/views/components/Tab.vue';
export default {
  name: "breadcrumbs",
  components: { TabNav, Tab},
  props: {
    currentPage: {
      required: true
    },
    textWhite: {
      type: String
    },
    selected: {
      type: String,
      required: true,
    },  
  },
      methods: {
        setTab(tab) {
            this.$emit('selected',tab);
        }
    }
};
</script>

<template>      
        <ul class="nav nav-tabs">
            <li v-for="tab in tabs" :key="tab" class="nav-item">
                <a class="nav-link" :class="{ active: tab === selected }" @click="setTab(tab)"> 
                    {{ tab }}
                </a>
            </li>
        </ul>

        <slot></slot>
    
</template>

<script>
export default {
    props: {
        tabs: {
            type: Symbol,
            required: true,
        },
        selected: {
            type: String,
            required: true,
        },

    },
    methods: {
        setTab(tab) {
            this.$emit('selected',tab);
        }
    }
}
</script>

<style>
.nav .nav-item .nav-link.active{
    opacity: 1;
    color: #404764;
    border-bottom: 2px solid #404764;
}
.nav .nav-item .nav-link{
    color:#404764;
    cursor: pointer;
}

</style>
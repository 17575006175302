<template>
<div class="container" id="elzero-app">
  <div id="inputHeader">
    <p id="inputHeaderTitlee">Modifiez votre modèle</p>
    <span id="inputHeaderEdit" class="glyphicon glyphicon-pencil">
      <div class="button">
      </div>
    </span>           
  </div>
  <div class="space"></div>
  <div class="card mb-4">
    <div class="py-4 container-fluid">
      <div v-if="responseData.error == false" :class="stats.icons.succesClassCard" role="alert">
        <div>
          <i :class="stats.icons.succesClass"></i>  {{ responseData.message }}
        </div>
      </div>
      <div v-if="responseData.error == true" :class="stats.icons.alertClassCard" role="alert">
        <div>
          <i :class="stats.icons.alertClass"></i>  {{ responseData.message }}
        </div>
      </div>

      <div class="row">
        <div class="col-6 d-flex align-items-center">
          <h6 class="mb-0">Liste des modèles</h6>
        </div>
        <div class="col-6 text-end">
          <button color="dark" variant="gradient" class="newfile" @click="goToCreateNewModele">
            <i class="fas fa-plus me-2"></i>
            Nouveau modèle
          </button>
        </div>
      </div>
      <div class="mt-4 row">
        <div class="col-12" align="center">
          
          <div class="tabs-content">
            <div class="list">
              <div class="row">
                <div class="col-12">
                  <div class=" row">
                    <div class="col-12">
                      <div class="card">
                        <div class="card-header pb-0 p-3">
                          <div class="row">
                            <div class="col-4 d-flex align-items-center">
                              <div class="input-group">
                                <input
                                  type="text"
                                  class="form-control"
                                  v-model="filter"
                                  :placeholder="
                                    'Recherche ...'
                                  "
                                />
                                <span class="input-group-text text-body">
                                  <i class="fas fa-search" aria-hidden="true"></i>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="card-body px-0 pt-0 pb-2">
                          <div class="table-responsive p-0">
                            <table class="table align-items-center mb-0">
                              <thead>
                                <tr>
                                  <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Type Dossier</th>
                                  <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Marque</th>
                                  <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Variante</th>
                                  <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Version</th>
                                  <th class="text-secondary opacity-7"></th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr v-for="vehicule in filteredRows" :key="vehicule.ref_vehicule_id">
                                  <td class="align-middle">
                                    <p class="text-xs font-weight-bold mb-0">{{vehicule.type_dossier}}</p>
                                  </td>
                                  <td class="align-middle text-center text-sm">
                                    <p class="text-xs font-weight-bold mb-0">{{vehicule.marque_veh}}</p>
                                  </td>
                                  <td class="align-middle text-center text-sm">
                                    <p class="text-xs font-weight-bold mb-0">{{vehicule.variante}}</p>
                                  </td>
                                  <td class="align-middle text-center text-sm">
                                    <p class="text-xs font-weight-bold mb-0">{{vehicule.version}}</p>
                                  </td>
                                  <td class="align-middle text-center text-sm">
                                    <div class="dropdownItem">
                                      <a class="dropdown" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        <i class="fas fa-ellipsis-v"></i>
                                      </a>
                                      <ul class="dropdown-menu">
                                        <li role="presentation">
                                          <a :class="stats.afficher.dropDownClass" @click="openModele(vehicule.ref_vehicule_id)">
                                            <span :class="stats.afficher.titleClass" :style="stats.afficher.style">Afficher</span>
                                            <i :class="stats.afficher.iconClass"></i>
                                          </a>
                                        </li>
                                        <li role="suppression" data-bs-toggle="modal" data-bs-target="#staticBackdrop" >
                                          <a :class="stats.supprimer.dropDownClass" @click="sendRefVehiculeId(vehicule.ref_vehicule_id)">
                                            <span :class="stats.supprimer.titleClass" :style="stats.supprimer.style">Supprimer</span>
                                            <i :class="stats.supprimer.iconClass"></i>
                                          </a>
                                        </li>
                                      </ul>
                                    </div>
                                    <!-- Modal -->
                                    <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                      <div class="modal-dialog">
                                        <div class="modal-content">
                                          <div class="modal-body">
                                            <div class="d-block text-center">
                                                <i class="bi bi-exclamation-circle"></i>
                                              <p>Êtes-vous sûr de vouloir supprimer ce dossier ?</p>
                                            </div>
                                          </div>
                                          <div class="modal-footer d-block text-center">
                                            <button type="button" class="btn btn-outline-secondary"  data-bs-dismiss="modal">Non</button>
                                            <button type="button" class="btn btn-outline-danger" @click="deleteModele(ref_modele_vehicule_id)"  data-bs-dismiss="modal" id="deleteButt">Oui</button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>                        
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import axios from 'axios';
import { mapMutations } from "vuex";
export default {
  name: 'App',
  data() {
    return {
      activeTab: "1",      
      vehicules : [],
      folders : [],
      ref_modele_vehicule_id: '',
      filter:'',
      stats: {
        supprimer: {
          dropDownClass: "dropdown-item d-flex justify-content-between align-items-center",
          titleClass: "me-auto",
          iconClass: "fas fa-solid fa-trash",
          style: "cursor: default;",
        },
        afficher: {
          dropDownClass: "dropdown-item d-flex justify-content-between align-items-center",
          titleClass: "me-auto",
          iconClass: "fas fa-regular fa-eye",
          style: "cursor: default;",
        },
        icons: {
          alertClass : '',
          alertClassCard : '',
          succesClass : '',
          succesClassCard : '',
        }
      },
      responseData: {
        message: '',
        error: null
      }
    }
  },
  created() {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token');
    axios.get('get_ref_vehicules').then(response => {
      this.vehicules = response.data.data.vehicules;
    });
  },
  methods: {
    ...mapMutations(["new_model"]),
    goToCreateNewModele() {
      this.new_model(null); 
      this.$router.push('model') 
    },
    openModele(modele_id) {
      this.new_model(modele_id);
      this.$router.push({name: 'Modèle', params: {modele_id: modele_id}}) 
    },
    sendRefVehiculeId(id){
      this.ref_modele_vehicule_id = id;
    },
    deleteModele(modele_id) {
      let formData = new FormData();
      formData.append('numberModele', modele_id);
      axios({method: 'POST', url: 'delete_modele',
          headers: {
            "Content-Type": "multipart/form-data"
          },
          data : formData
        }).then(response => {
          this.responseData.error = response.data.error;
          if(this.responseData.error == false) {
            this.responseData.message = response.data.message;
            this.stats.icons.succesClass = "bi bi-check";
            this.stats.icons.succesClassCard = "alert alert-success d-flex align-items-center";
            setTimeout(() => {
              window.location.reload();
            }, 2500);
          } else {
            this.responseData.message = response.data.message;
            this.stats.icons.alertClass = "bi bi-exclamation-triangle";
            this.stats.icons.alertClassCard = "alert alert-danger d-flex align-items-center";
            setTimeout(() => {
              this.responseData.message = null;
              this.stats.icons.alertClass = null;
              this.stats.icons.alertClassCard = null;
            }, 2500);
          }
        });
    }
  },
  computed: {
    filteredRows() {
    return this.vehicules.filter(row => {
      const type_dossier = row.type_dossier.toString().toLowerCase();
      const marque_veh = row.marque_veh.toString().toLowerCase();
      const variante = row.variante.toString().toLowerCase();
      const version = row.version.toString().toLowerCase();
      
      const searchTerm = this.filter.toLowerCase();

      return type_dossier.includes(searchTerm) || marque_veh.includes(searchTerm) || variante.includes(searchTerm) || version.includes(searchTerm);
    });
  }
  }
   
}
</script>


<style>
#phone-input {
    position: relative;
  }
  
#phone-input + .dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: white;
  border: 1px solid #ccc;
  z-index: 1;
}

.dropdown ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.dropdown li {
  padding: 10px;
  cursor: pointer;
}

.dropdown li:hover {
  background-color: #eee;
}
.space{
  height: 20px;
}
#inputHeader {
  width: 100%;
  border-radius: 10px 10px 0px 0px;
  display: flex;
  align-items: center;
  padding: 0 10px;
  box-sizing: border-box;
}
#inputHeaderTitlee {
  color:white;
  font-size:20px;
  display: inline-block;
  flex: 1;
}
#inputHeaderEdit {
  display: inline-block;
}
.empty-space {
  height: 150px;
}
.color {
  color: #404764;
  font-weight: bold;
}
.btnright{
    margin-left:10px;   
    cursor: pointer;
    outline: 0;
    display: inline-block;
    font-weight: 400;
    line-height: 1.5;
    text-align: center;
    background-color: #fff;
    border: 1px solid #404764;
    padding: 6px 12px;
    font-size: 1rem;
    border-radius: .25rem;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    color: #404764;
    border-color: #404764;
}
.btnleft{
    margin-right:10px;    
    cursor: pointer;
    outline: 0;
    color: #fff;
    background-color: #404764;
    border-color: red;
    display: inline-block;
    font-weight: 400;
    line-height: 1.5;
    text-align: center;
    border: 1px solid transparent;
    padding: 6px 12px;
    font-size: 16px;
    border-radius: .25rem;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.container {
  margin: 60px auto;
  width: 100%;
}  
.container .tabs-choose {
    margin-bottom: 0;
}
.button {
  text-align: center;
} 
.container .tabs-choose li {
      display: inline-block;
      padding: 10px 20px;
      color:#404764;
      background-color: #f3f5f9;
      cursor: pointer;
      opacity: 0.3;
    }

.container .tabs-choose li.active{
  opacity: 1;
  color: #004aad;
  border-bottom: 2px solid #004aad;
}  
.container .tabs-content {
    background-color: #f3f5f9;
    margin: 0 auto;
    padding: 20px;
    border-radius:10px;
  }
.bkimg {
  position: relative;
  width: 100%;
  max-width: 400px;
  text-align: center;
}
.grid{
  margin-left:10px;   
    cursor: pointer;
    outline: 0;
    display: inline-block;
    font-weight: 400;
    line-height: 1.5;
    text-align: center;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 6px 12px;
    font-size: 1rem;
    border-radius: .25rem;
}
.bkimg img {
  width: 100%;
  border-radius: 5%;
  margin-left: auto;
  margin-right: auto;
  
}

.bkimg .btn {
  position: absolute;
  top: 80%;
  left: 65%;
  background-color: #404764;
  color: white;
  font-size: 12px;
  padding: 12px 24px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  text-align: center;
}
.bkimg .para {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 12px;
  padding: 12px 24px;
  font-weight: bold;
}
.bkimg .para1 {
  position: absolute;
  color:white;
  top: 0;
  left: 0;
  font-size: 12px;
  padding: 12px 24px;
  font-weight: bold;
}
.bkimg .btn:hover {
  background-color: black;
}
.list{
  test-align:center;
}
.image{
    width: 180px;
    height:100px;
    border-radius:5px;
}
.checkbox{
    margin-left:8px;
}
.btnleft{ 
    cursor: pointer;
    outline: 0;
    color: #fff;
    background-color: #404764;
    border-color: red;
    display: inline-block;
    font-weight: 400;
    line-height: 1.5;
    text-align: center;
    border: 1px solid transparent;
    padding: 6px 12px;
    font-size: 16px;
    border-radius: .25rem;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.btnleft .fas{
    color: #404764;
    font-size:24px;
}
</style>

<template>
  <div
    class="collapse navbar-collapse w-auto h-auto h-100"
    id="sidenav-collapse-main"
  >
    <ul class="navbar-nav">
      <li class="nav-item">
        <sidenav-item
          url="/dashboard"
          :class="getRoute() === 'dashboard' ? 'active' : ''"
          :navText="'Tableau de bords'"
        >
          <template v-slot:icon>
            <i class="ni ni-tv-2 text-primary text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>
      <li class="nav-item">
        <sidenav-item
          url="/repositories"
          :class="getRoute() === 'repositories' ? 'active' : ''"
          :navText="'Créer Dossiers avec OCR'"
        >
          <template v-slot:icon>
            <i
              class="ni ni-folder-17 text-warning text-sm opacity-10"
            ></i>
          </template>
        </sidenav-item>
      </li>
      <li class="nav-item">
        <sidenav-item
          url="/models"
          :class="getRoute() === 'models' ? 'active' : ''"
          :navText="
            'Créer Dossiers avec Modèle'
          "
        >
          <template v-slot:icon>
            <i class="ni ni-single-copy-04 text-info text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>
      <li class="mt-3 nav-item">
        <h6
          class="text-xs ps-4 text-uppercase font-weight-bolder opacity-6"
          :class="'ms-2'"
        >
          Administration
        </h6>
      </li>
      <li class="nav-item">
        <sidenav-item
          url="/modeles"
          :class="getRoute() === ('modeles'|| 'model/create') ? 'active' : ''"
          :navText="
            'Modèles'
          "
        >
          <template v-slot:icon>
            <i class="ni ni-archive-2 text-info text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>
      <li class="mt-3 nav-item">
        <h6
          class="text-xs ps-4 text-uppercase font-weight-bolder opacity-6"
          :class="'ms-2'"
        >
          COMPTE
        </h6>
      </li>
      <li class="nav-item">
        <a href="javascript:void(0)" @click="handleSignOut" class="nav-link">
          <div class="icon icon-shape icon-sm text-center d-flex align-items-center justify-content-center">
            <i class="ni ni-single-copy-04 text-danger text-sm opacity-10"></i>
          </div>
          <span class="nav-link-text ms-1">Déconnexion</span>
        </a>
      </li>
    </ul>
  </div>
  <div class="pt-3 mx-3 mt-3 sidenav-footer">
    <sidenav-card
      :class="cardBg"
      textPrimary="Need Help?"
      textSecondary="Please check our docs"
    />
  </div>
</template>
<script>
import SidenavItem from "./SidenavItem.vue";
import SidenavCard from "./SidenavCard.vue";

export default {
  name: "SidenavList",
  props: {
    cardBg: String
  },
  data() {
    return {
      title: "Argon Dashboard 2",
      controls: "dashboardsExamples",
      isActive: "active"
    };
  },
  components: {
    SidenavItem,
    SidenavCard
  },
  methods: {
    getRoute() {
      const routeArr = this.$route.path.split("/");
      return routeArr[1];
    },
    handleSignOut() {
      localStorage.removeItem('token');
      localStorage.removeItem('user_id');
      this.$router.push('/');
    }
  }
};
</script>

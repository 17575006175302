import { createStore } from "vuex";

export default createStore({
  state: {
    hideConfigButton: false,
    isPinned: true,
    showConfig: false,
    sidebarType: "bg-white",
    mcolor: "",
    darkMode: false,
    isNavFixed: false,
    isAbsolute: false,
    showNavs: true,
    showSidenav: true,
    showNavbar: true,
    showFooter: true,
    showMain: true,
    layout: "default",
    folder_number: "",
    type_dossier: "",
    marque_veh: "",
    variante: "",
    version: "",
    modeleId:""
  },
  mutations: {
    folder_number(state, payload){
      state.folder_number = payload;
    },
    new_model(state, payload){
      state.modeleId = payload;
    },
    new_folder_by_model(state, payload){
      state.type_dossier = payload.type_dossier;
      state.marque_veh = payload.marque_veh;
      state.variante = payload.variante;
      state.version = payload.version;
    },
    toggleConfigurator(state) {
      state.showConfig = !state.showConfig;
    },
    navbarMinimize(state) {
      const sidenav_show = document.querySelector(".g-sidenav-show");

      if (sidenav_show.classList.contains("g-sidenav-hidden")) {
        sidenav_show.classList.remove("g-sidenav-hidden");
        sidenav_show.classList.add("g-sidenav-pinned");
        state.isPinned = true;
      } else {
        sidenav_show.classList.add("g-sidenav-hidden");
        sidenav_show.classList.remove("g-sidenav-pinned");
        state.isPinned = false;
      }
    },
    sidebarType(state, payload) {
      state.sidebarType = payload;
    },
    navbarFixed(state) {
      if (state.isNavFixed === false) {
        state.isNavFixed = true;
      } else {
        state.isNavFixed = false;
      }
    }
  },
  getters: {
    folder_number : (state) => state.folder_number,
    new_model : (state) => state.modeleId,
    new_folder_by_model : (state) => state.type_dossier,
    new_folder_by_model : (state) => [state.type_dossier, state.marque_veh, state.variante, state.version]
  },
  actions: {
    toggleSidebarColor({ commit }, payload) {
      commit("sidebarType", payload);
    }
  }
});

<template>
  <v-select :options="options" label="title">
    <template v-slot:option>
      <img :src="option.cardImage" />
      {{ option.title }}
    </template>
  </v-select>
  <div class="container" id="elzero-app">
    <div id="inputHeader">
      <p id="inputHeaderTitle"></p>
      <span id="inputHeaderEdit" class="glyphicon glyphicon-pencil">
        <div class="button">
          <button type="button" id="slide_start_button" value="Start" class="btnleft" @click="goBack">Retour</button>
        </div>
      </span>
    </div>
    <div class="space"></div>
    <div class="card mb-4">
      <div>
        <div class="card-header pb-0">
          <div id="inputHeader">
            <h6 v-if="data.folder_number" id="inputHeaderTitle"><span class="badge bg-gradient-success">Numéro Dossier : {{ data.folder_number }}</span> 
              &nbsp; <span class="badge" :class="data.folder_status_color">{{ data.folder_status_lib }}</span>
            </h6>
            <h6 v-else id="inputHeaderTitle">Création d'un nouveau dossier</h6>
          </div>
          <div v-if="errors.length" class="alert alert-danger">
            <b>Veuillez corriger les erreurs suivantes:</b>
            <ul>
              <li v-for="(error, index) in errors" :key="index">{{ error }}</li>
            </ul>
          </div>
          <div v-if="data.utac_response">
            <ul class="list-group">
              <li class="mb-2 border-0 list-group-item d-flex justify-content-between border-radius-lg p-4 bg-gray-100">
                <div class="d-flex align-items-center">
                    <div class="d-flex flex-column">
                      <h6 class="mb-1 text-sm text-dark">Réponse UTAC</h6>
                    </div>
                    </div>
                    <div class="d-flex"><button @click="data.show_utac_response = !data.show_utac_response" class="my-auto btn btn-link btn-icon-only btn-rounded btn-sm text-dark icon-move-right"><i class="ni ni-bold-right" aria-hidden="true"></i></button>
                </div>
              </li>
              <li v-if="data.show_utac_response" class="mb-2 border-0 list-group-item d-flex justify-content-between border-radius-lg p-4 bg-gray-100">
                <span class="mb-2 text-xs"> JSON: <span class="text-dark font-weight-bold ms-sm-2"><pre>{{ data.utac_response  }}</pre></span></span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div>
        <!-- <h6></h6> -->
        
      </div>
      

      <!-- <div v-if="activeTab === '1'">
        <div class="card-header pb-0">
          <div id="inputHeader">
            <p v-if="data.folder_number" id="inputHeaderTitle"><span class="badge bg-gradient-success">Numéro Dossier : {{ data.folder_number }}</span> 
              &nbsp; <span class="badge" :class="data.folder_status_color">{{ data.folder_status_lib }}</span>
            </p>
              <p v-else id="inputHeaderTitle">Création d'un nouveau dossier</p>
            <span id="inputHeaderEdit" class="glyphicon glyphicon-pencil">
              <div class="button">
                <button type="button" id="slide_start_button" value="Start" class="btnleft">Précédent</button>
                <button type="button" id="slide_start_button" value="Start" class="btnright"
                  @click="nextTab1()">Suivant</button>
              </div>
            </span>
          </div>
        </div>
      </div>
      <div v-if="activeTab === '2'">
        <div class="card-header pb-0">
          <div id="inputHeader">
            <p v-if="data.folder_number" id="inputHeaderTitle"><span class="badge bg-gradient-success">Numéro Dossier : {{ data.folder_number }}</span>
              &nbsp; <span class="badge" :class="data.folder_status_color">{{ data.folder_status_lib }}</span>
            </p>
            <p v-else id="inputHeaderTitle">Création d'un nouveau dossier</p>
            <span id="inputHeaderEdit" class="glyphicon glyphicon-pencil">
              <div class="button">
                <button type="button" id="slide_start_button" value="Start" class="btnleft"
                  @click="prevTab2()">Précédent</button>
                <button type="button" id="slide_start_button" value="Start" class="btnright"
                  @click="nextTab2()">Suivant</button>
              </div>
            </span>
          </div>
        </div>
      </div>
      <div v-if="activeTab === '3'">
        <div class="card-header pb-0">
          <div id="inputHeader">
            <p v-if="data.folder_number" id="inputHeaderTitle"><span class="badge bg-gradient-success">Numéro Dossier : {{ data.folder_number }}</span>
              &nbsp; <span class="badge" :class="data.folder_status_color">{{ data.folder_status_lib }}</span>
            </p>
            <p v-else id="inputHeaderTitle">Création d'un nouveau dossier</p>
            <span id="inputHeaderEdit" class="glyphicon glyphicon-pencil">
              <div class="button">
                <button type="button" id="slide_start_button" value="Start" class="btnleft"
                  @click="prevTab1()">Précédent</button>
                <button type="button" id="slide_start_button" value="Start" class="btnright">Suivant</button>
              </div>
            </span>
          </div>
        </div>
      </div> -->
      <div class="py-4 container-fluid">
        <div class="mt-4 row">
          <div class="col-12">
            <ul class="tabs-choosee">
              <li @click="activeTab = '1'" :class="[activeTab === '1' ? 'active' : '']"><i style='font-size:24px' class='fas'>&#xf07b;</i> Document</li>
              <li @click="activeTab = '2'" :class="[activeTab === '2' ? 'active' : '']"><i style='font-size:24px' class='fas'>&#xf1b9;</i> Véhicule</li>
              <li @click="activeTab = '3'" :class="[activeTab === '3' ? 'active' : '']"><i style='font-size:24px' class='fas'>&#xf007;</i> Distributeur</li>
            </ul>
            <div class="tabs-content">
              <div class="content-one" v-if="activeTab === '1'">
                <div class="row">
                  <div class="space"></div>
                  <div class="col-12">
                    <div class=" row">
                      <div class="col-12">
                        <div class="empty"></div>
                          <div class="margin">
                            <div class="row">
                              <div class="col-md-2">
                                <label for="example-text-input" class="form-control-label">Nouveau dossier</label>
                              </div>
                              <div class="col-md-5">
                                <!-- <div class="inputfile-box">
                                  <div v-if="data.folder_number">
                                    <a :href="data.fileCoC.filePath" target="_blank">{{ data.fileCoC.fileName }}</a>
                                  </div>
                                  <div v-else>
                                    <input type="file" id="file" ref="file" v-on:change="onChangeFileUpload()"/>
                                    <label for="file">
                                      <span id="file-name" class="file-box"></span>
                                      <span class="file-button"><i class='fas'>&#xf382;</i></span>
                                    </label>
                                  </div>
                                </div> -->

                                <div class="inputfile-box">
                                  <div v-if="data.folder_number">
                                    <div class="fileUploadInput">
                                      <input  type="file" id="file" placeholder="Choose a file" ref="file" v-on:change="onChangeFileUpload()"/>
                                      <button><i class="fas fa-cloud-upload-alt"></i></button>
                                    </div>
                                    <a :href="data.fileCoC.filePath" target="_blank">{{ data.fileCoC.fileName }}</a>
                                  </div>
                                  <div v-else >
                                    <div class="fileUploadInput">
                                      <input  type="file" id="file" placeholder="Choose a file" ref="file" v-on:change="onChangeFileUpload()"/>
                                      <button><i class="fas fa-cloud-upload-alt"></i></button>
                                    </div>
                                  </div>  
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-md-2">
                                <label for="example-text-input" class="form-control-label">Type du dossier</label>
                              </div>
                              <div class="col-md-5">
                                <div class="selectWrapper">
                                  <select v-model="data.template_code">
                                    <option selected>Aucun</option>
                                    <option v-for="template in templates" :value="template.code" :key="template.template_id">{{ template.name }}</option>
                                  </select>
                                </div> 
                              </div>
                            </div>
                          </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- <div>
                  <img class="ocrgif"
                    src="@/assets/img/ocr_gif.gif"
                    alt="ocrgif"
                  />
                </div> -->
                <div class="empty-space">
                  
                  <img v-if="spinner" class="spinner"
                    src="@/assets/img/spinner.gif"
                    alt="spinner"
                  />
                </div>
              </div>
              <div class="content-one" v-if="activeTab === '2'">
                <div class="row">
                  <div class="col-md-6">
                    <label for="example-text-input" class="form-control-label">Manufacturer <span style="color: red;">*</span></label>
                    <input class="form-control" type="text" v-model="data.vehicule.manufacturer"/>
                  </div>
                  <div class="col-md-6">
                    <label for="example-text-input" class="form-control-label">Type <span style="color: red;">*</span></label>
                    <input class="form-control" type="text" v-model="data.vehicule.type"/>
                  </div>
                  <div class="col-md-6">
                    <label for="example-text-input" class="form-control-label">Variant <span style="color: red;">*</span></label>
                    <input class="form-control" type="text" v-model="data.vehicule.variant"/>
                  </div>
                  <div class="col-md-6">
                    <label for="example-text-input" class="form-control-label">Version <span style="color: red;">*</span></label>
                    <input class="form-control" type="text" v-model="data.vehicule.version"/>
                  </div>
                  <div class="col-md-6">
                    <label for="example-text-input" class="form-control-label">Nom commercial <span style="color: red;">*</span></label>
                    <input class="form-control" type="text" v-model="data.vehicule.commercial_name" />
                  </div>
                  <div class="col-md-6">
                    <label for="example-text-input" class="form-control-label">Numéro VIN <span style="color: red;">*</span></label>
                    <input class="form-control" type="text" v-model="data.vehicule.vin"/>
                  </div>
                </div>
                <div class="empty-space"></div>
                
              </div>
              <div class="content-one" v-if="activeTab === '3'">
                <div class="row">
                  <div class="col-md-6">
                    <label for="example-text-input" class="form-control-label">Nom commercial</label>
                    <input class="form-control" type="text" v-model="data.distributer.trade_name"/>
                  </div>
                  <div class="col-md-6">
                    <label for="example-text-input" class="form-control-label">N° SIREN</label>
                    <input class="form-control" type="text" v-model="data.distributer.siren"/>
                  </div>
                  <div class="col-md-6">
                    <label for="example-text-input" class="form-control-label">Type de point de vente</label>
                    <input class="form-control" type="text" v-model="data.distributer.point_of_sale"/>
                  </div>
                  <div class="col-md-6">
                    <label for="example-text-input" class="form-control-label">E-mail</label>
                    <input class="form-control" type="text" v-model="data.distributer.email"/>
                  </div>
                  <div class="col-md-6">
                    <div class="tel">
                      <label for="example-text-input" class="form-control-label">Téléphone pro.</label>
                      <input class="form-control" type="text" v-model="data.distributer.pro_phone_number"/>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="tel">
                      <label for="example-text-input" class="form-control-label">Téléphone fixe</label>
                      <input class="form-control" type="text" v-model="data.distributer.fixed_phone_number"/>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <label for="example-text-input" class="form-control-label">Address</label>
                    <textarea class="form-control ph" rows="5" v-model="data.distributer.address"></textarea>
                  </div>
                </div>
                <div class="empty-space"></div>
                
              </div>

              <div class="button">
                  <div v-if="data.folder_number">
                    <button type="button" id="slide_start_button" value="Start" class="btnright" v-on:click.prevent="update()">Mettre à jour</button>
                  </div>
                  <div v-else>
                    <button type="button" id="slide_start_button" value="Start" class="btnright" v-on:click.prevent="submit()">Enregistrer</button>
                  </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from "vuex";
export default {
  name: 'App',
  props: ['folder_number', 'type_dossier', 'marque_veh', 'variante', 'version'],
  data() {
    return {
      activeTab: "1",
      file: '',
      templates: [],
      data: {
        template_code: "",
        user_id: "",
        folder_number: "",
        folder_status_color: "",
        folder_status_lib: "",
        utac_response: "",
        show_utac_response: false,
        vehicule: {
          manufacturer : "",
          type : "",
          variant : "",
          version : "",
          commercial_name : "",
          vin : ""
        },
        distributer: {
          trade_name : "",
          siren : "",
          point_of_sale : "",
          email : "",
          pro_phone_number : "",
          fixed_phone_number : "",
          address : ""
        },
        fileCoC: {
          fileName: "",
          filePath: ""
        }
      },
      errors: [],
      spinner: false,
      uploading: false,
      uploaded: false,
      uploadedAfter: false,
    }
  },
  created() {
    //get folder if it's update et voir satuts
    if(this.$store.getters.folder_number) {
      let formData = new FormData();
      formData.append('number', this.$store.getters.folder_number);
      axios({method: 'POST', url: 'get_folder',
            headers: {
              "Content-Type": "multipart/form-data"
            },
            data : formData
          }).then(response => {
            this.data.folder_number = response.data.folder_number;
            this.data.folder_status_color = response.data.folder_status_color;
            this.data.folder_status_lib = response.data.folder_status_lib;
            this.data.utac_response = JSON.stringify(JSON.parse(response.data.utac_response), null, 2);
            //vehicule
            this.data.vehicule.manufacturer = response.data.data.vehicule.manufacturer;
            this.data.vehicule.type = response.data.data.vehicule.type;
            this.data.vehicule.variant = response.data.data.vehicule.variant;
            this.data.vehicule.version = response.data.data.vehicule.version;
            this.data.vehicule.commercial_name = response.data.data.vehicule.commercialName;
            this.data.vehicule.vin = response.data.data.vehicule.vin;
            //distributer
            this.data.distributer.trade_name = response.data.data.distributer.tradeName;
            this.data.distributer.siren = response.data.data.distributer.siren;
            this.data.distributer.point_of_sale = response.data.data.distributer.pointOfSale;
            this.data.distributer.email = response.data.data.distributer.email;
            this.data.distributer.pro_phone_number = response.data.data.distributer.proPhoneNumber;
            this.data.distributer.fixed_phone_number = response.data.data.distributer.fixedPhoneNumber;
            this.data.distributer.address = response.data.data.distributer.address;
            //file
            if(response.data.data.file) {
              this.data.fileCoC.fileName = response.data.data.file.fileName;
              this.data.fileCoC.filePath = response.data.data.file.filePath;
              this.data.template_code = response.data.data.file.template.templateCode;
            }else {
              this.activeTab = "2";
            }
          });
    }
    
    if(this.$store.getters.new_folder_by_model[0]) {
      this.activeTab = "2";
      this.data.vehicule.manufacturer = this.$store.getters.new_folder_by_model[1]; //marque_veh;
      //ToDo ask about commercial name if it's always equal to type ?
      this.data.vehicule.type = this.$store.getters.new_folder_by_model[0]; //type_dossier;
      this.data.vehicule.commercial_name = this.$store.getters.new_folder_by_model[1]; //marque_veh;
      this.data.vehicule.variant = this.$store.getters.new_folder_by_model[2]; //variante;
      this.data.vehicule.version = this.$store.getters.new_folder_by_model[3]; //version;
    }
    this.data.user_id = localStorage.getItem('user_id');
    axios.get('get_templates').then(response => {
      this.templates = response.data.templates;
    });
  },
  methods: {
    sleep(time) {
      return new Promise(resolve => setTimeout(resolve, time));
    },
    async startUpload() {
      this.uploading = true;
      await this.sleep(3000);
      this.uploaded = true;
      await this.sleep(2000);
      this.uploading = false;
      this.uploadedAfter = true;
      await this.sleep(1000);
      this.uploading = false;
      this.uploaded = false;
      this.uploadedAfter = false;
    },
    // nextTab1() {
    //   this.activeTab = "2";
    // },
    // nextTab2() {
    //   this.activeTab = "3";
    // },
    // prevTab1() {
    //   this.activeTab = "2";
    // },
    // prevTab2() {
    //   this.activeTab = "1";
    // },
    goBack() {
      this.$router.go(-1)
    },
    checkRequiredField(data, file) {
      this.errors = [];

      if(file) {
        if (!data.template_code) {
          this.errors.push('Veuillez selectionner un type de dossier.');
        }
      }else {
        if (!data.vehicule.manufacturer) {
          this.errors.push('Champs manufacturer véhicule Obligatoire.');
        }

        if (!data.vehicule.type) {
          this.errors.push('Champs type véhicule Obligatoire.');
        }

        if (!data.vehicule.variant) {
          this.errors.push('Champs variant véhicule Obligatoire.');
        }

        if (!data.vehicule.version) {
          this.errors.push('Champs version véhicule Obligatoire.');
        }

        if (!data.vehicule.commercial_name) {
          this.errors.push('Champs nom commercial véhicule Obligatoire.');
        }

        if (!data.vehicule.vin) {
          this.errors.push('Champs numéro VIN véhicule Obligatoire.');
        }
      }

      if (!this.errors.length) {
        return true;
      } else {
        return false;
      }

    },
    submit(){
      this.errors = [];
      let formData = new FormData();
      formData.append('coc', this.file);
      const dataString = JSON.stringify(this.data);
      formData.append('data', dataString);
      this.spinner = true;
      if(this.checkRequiredField(this.data, this.file)) {
        axios({method: 'POST', url: 'create_new_folder',
          headers: {
            "Content-Type": "multipart/form-data"
          },
          data : formData
        }).then(response => {
          if(response.data.error) {
            if(response.data.message) {
              this.errors.push(response.data.message);
            } else {
              this.errors.push("Erreur création");
            }
          }else {
            this.data.folder_number = response.data.data.folder.folderNumber;
            this.data.folder_status_color = response.data.folder_status_color;
            this.data.folder_status_lib = response.data.folder_status_lib;
            //vehicule
            this.data.vehicule.manufacturer = response.data.data.vehicule.manufacturer;
            this.data.vehicule.type = response.data.data.vehicule.type;
            this.data.vehicule.variant = response.data.data.vehicule.variant;
            this.data.vehicule.version = response.data.data.vehicule.version;
            this.data.vehicule.commercial_name = response.data.data.vehicule.commercialName;
            this.data.vehicule.vin = response.data.data.vehicule.vin;
            //distributer
            this.data.distributer.trade_name = response.data.data.distributer.tradeName;
            this.data.distributer.siren = response.data.data.distributer.siren;
            this.data.distributer.point_of_sale = response.data.data.distributer.pointOfSale;
            this.data.distributer.email = response.data.data.distributer.email;
            this.data.distributer.pro_phone_number = response.data.data.distributer.proPhoneNumber;
            this.data.distributer.fixed_phone_number = response.data.data.distributer.fixedPhoneNumber;
            this.data.distributer.address = response.data.data.distributer.address;
          }
          this.spinner = false;
          this.activeTab = "2";
        });
      } else {
        this.spinner = false;
      }

      
      },
    update(){
      this.errors = [];
      let formData = new FormData();
      const dataString = JSON.stringify(this.data);
      formData.append('data', dataString);
      this.spinner = true;
      if(this.checkRequiredField(this.data)) {
        axios({method: 'POST', url: 'update_folder',
          headers: {
            "Content-Type": "multipart/form-data"
          },
          data : formData
        }).then(response => {
          if(response.data.error) {
            if(response.data.message) {
              this.errors.push(response.data.message);
            } else {
              this.errors.push("Erreur mise à jour");
            }
          }
          this.spinner = false;
        });
      }else {
        this.spinner = false;
      }
      
    },
    onChangeFileUpload(){
      this.file = this.$refs.file.files[0];
    }
  },
  computed: {
    ...mapGetters([
      "folderNumber"
    ]),
  }
}
</script>

<style>
#phone-input {
  position: relative;
}

#phone-input+.dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: white;
  border: 1px solid #ccc;
  z-index: 1;
}

.dropdown ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.dropdown li {
  padding: 10px;
  cursor: pointer;
}

.dropdown li:hover {
  background-color: #eee;
}

.space {
  height: 20px;
}

#inputHeader {
  width: 100%;
  border-radius: 10px 10px 0px 0px;
  display: flex;
  align-items: center;
  padding: 0 10px;
  box-sizing: border-box;
}

#inputHeaderTitle {
  color: black;
  display: inline-block;
  flex: 1;
}

#inputHeaderEdit {
  display: inline-block;
}

.empty-space {
  height: 20px;
}

.spinner {
  left: 50%;
  position: absolute;
  text-align: center;
  top: 50%;
  height: 150px;
  width: 150px;
}

.ocrgif {
  left: 30%;
  position: absolute;
  text-align: center;
  top: 45%;
  /* height: 150px;
  width: 150px; */
}

.space {
  height: 10px;
}

.color {
  color: #404764;
  font-weight: bold;
}

.btnright {
  margin-left: 10px;
  cursor: pointer;
  outline: 0;
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  background-color: #fff;
  border: 1px solid #404764;
  padding: 6px 12px;
  font-size: 1rem;
  border-radius: .25rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  color: #404764;
  border-color: #404764;
}

.btnright:hover {
  cursor: pointer;
  outline: 0;
  color: #fff;
  background-color: #404764;
  border-color: red;
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  border: 1px solid transparent;
  padding: 6px 12px;
  font-size: 16px;
  border-radius: .25rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.btnleft {
  margin-right: 10px;
  cursor: pointer;
  outline: 0;
  color: #fff;
  background-color: #404764;
  border-color: red;
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  border: 1px solid transparent;
  padding: 6px 12px;
  font-size: 16px;
  border-radius: .25rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.btnleft:hover {
  cursor: pointer;
  outline: 0;
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  background-color: #fff;
  border: 1px solid #404764;
  padding: 6px 12px;
  font-size: 1rem;
  border-radius: .25rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  color: #404764;
  border-color: #404764;
}

.container {
  margin: 60px auto;
  width: 100%;
}

.container .tabs-choosee {
  margin-bottom: 0;
}

.button {
  text-align: center;
}

.tabs-choosee li {
  display: inline-block;
  padding: 10px 20px;
  color: #404764;
  background-color: #f3f5f9;
  cursor: pointer;
  opacity: 0.3;
}

.tabs-choosee li.active {
  opacity: 1;
  color: #404764;
  border-bottom: 2px solid #404764;
}

.container .tabs-content {
  background-color: #f3f5f9;
  margin: 0 auto;
  padding: 20px;
  border-radius: 10px;
}

.design {
  color: red;
}


.upload-box {
  font-size: 16px;
  background: white;
  box-shadow: 5px 5px 10px black;
  width: 350px;
  outline: none;
}


.fileUploadInput {
  display: grid;
  grid-gap: 10px;
  position: relative;
  z-index: 1;
}

.fileUploadInput label {
  display: flex;
  align-items: center;
  color: setColor(primary, 0.5);
  background: setColor(white);
  transition: .4s ease;
  font-family: arial, sans-serif;
  font-size: .75em;
  font-weight: regular;
}

.fileUploadInput input {
  position: relative;
  width: 100%;
  height: 50px;

  font-family: arial, sans-serif;
  font-size: 1rem;


  cursor: pointer;
  font-weight: regular;
}

.fileUploadInput input[type="file"]::-webkit-file-upload-button {

  margin-left: 10px;
  padding: 0;
  height: 50px;
  width: 0;
}

.fileUploadInput button {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 50px;
  height: 50px;
  line-height: 0;
  user-select: none;
  color: white;
  background-color: #323262;
  border-radius: 0 3px 3px 0;
  font-family: arial, sans-serif;
  font-size: 1rem;
  font-weight: 800;
}

.fileUploadInput button svg {
  width: auto;
  height: 50%;
}

::file-selector-button {
  display: none;
}

.file-button {
  cursor: pointer;
  outline: 0;
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  background-color: #404764;
  border: 1px solid #404764;
  padding: 6px 12px;
  font-size: 1rem;
  border-radius: .25rem;
  color: white;
  border-color: #404764;
}

.file-button .fas {
  color: white;
}

.inputfile-box .inputfile {
  border: 1px solid #cccccc;
  border-radius: 5px;
  background-color: white;
  width: 700px;
}

.tel input[type="text"] {
  height: 30px;
  line-height: 30px;
}

select {
  height: 30px;
  line-height: 30px;
}

.imagee {
  height: 30px;
  line-height: 30px;
}

.btnExample {
  height: 30px;
  line-height: 30px;
}

.selectWrapper {
  border-radius: 6px;
  display: inline-block;
  /* overflow: hidden; */
  background: #cccccc;
  /* border: 1px solid #cccccc; */
  
  /* position: relative; */
  height: 0% !important;
  width: 100%;
}

.selectBox {
  width: 140px;
  height: 30px;
  border: 0px;
  outline: none;
}

.try {
  background: white;
  border: none;
}

select {
  width: 100%;
  height: 26px; 
  padding: 1%;
  padding-bottom: 0%;
  padding-top: 0.1%;
  border: none;
  border-radius: 5px;
  color: #67748e;
}

select:focus {
  outline: none; /* Remove outline on focus */
}

/* --------------- */
/* File Upload Input Styles */
.fileUploadInput {
  display: grid;
  grid-gap: var(--gap);
  position: relative;
  z-index: 1;
}.fileUploadInput {
  display: flex;
  align-items: center;
  color: hsla(240, 33, 29, 0.5);
  background: white;
  transition: 0.4s ease;
  font-family: arial, sans-serif;
  font-size: 0.75em;
  font-weight: regular;
}
input[type=file]::before {
    content: "  ";
}
.fileUploadInput input {
  position: relative;
  z-index: 1;
  padding: 100%;
  width: 100%;
  height: 10%;
  font-family: arial, sans-serif;
  font-size: 1rem;
  /* font-size: 1.15rem; */
  font-weight: regular;
}

.fileUploadInput input[type="file"] {
  padding: 0 var(--gap);
}

.fileUploadInput input[type="file"]::-webkit-file-upload-button {
  visibility: hidden;
  padding: 0;
  width: 0px;
}

.fileUploadInput button {
  position: absolute;
  right: 0;
  bottom: 0;
  height: 100%;
  border: none;
  line-height: 0;
  font-size: 1rem;
  font-weight: 800;
}

</style>
